@import 'bootstrap-3.3.7/less/mixins.less';
@import 'bootstrap-3.3.7/less/variables.less';

@import '_svgicons.less';
@import '_util.less';
@import '../modules/newsroom/app/assets/stylesheets/_pagePreviewIndicator.less';

@sans-serif-font-stack: 'Lato', 'Nimbus Sans', sans-serif;

:root {
  /* Channel, Release, Basket, Search Results Banner min-height */
  --banner-min-height: 200px;
  @media(max-width: @screen-md-max) {
    --banner-min-height: 134px;
  }
}

/* ==== GLOBAL STYLES =================================================== */
/* ====================================================================== */
html {
  body {
    color: @black;
    font-family: @sans-serif-font-stack;

    min-height: 100vh;

    > footer {
      position: sticky;
      top: 100%;
    }
  }
  h1, h2 {
    color: @dark-gray;
    a {
      color: @dark-gray;
    }
  }
  h3, h4, h5, h6 {
    color: @black;
    a {
      color: @black;
    }
  }
}
*:focus {
  outline-style: none;
}
a, button, .site-navbar-nav a {
  transition: background ease-in-out .25s;
  &:focus {
    outline-style:0;
  }
}
a {
  color: @black;
  &:hover, &:focus {
    color: var(--market-main);
  }
}
.site-page-header h2 {
  text-transform: uppercase;
}
textarea:focus, input:focus{
  outline-style: none;
}
input.form-control {
  border-radius: 0;
  &:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
a:focus, a:hover {
  text-decoration: none;
}
.btn-default, .btn-group .btn-default {
  border-radius: 0;
}
select {
  float: left;
  width: auto!important;
  border-radius: 0px!important;
  margin-right: 6px!important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0px!important;
  background-color: white!important;
  line-height: 12px!important;
}
div#flash-info {
  margin-top: 40px;
  margin-bottom: 40px;
}
.alert-error {
  background-color: #FFF;
  border: 1px solid var(--market-main);
  border-radius: 0px;
  box-shadow: 0px 0px 15px rgba(178,0,0,0.2);
  color: var(--market-main);
  margin-bottom: 20px;
  padding: 15px;
  .close {
    right: 0;
  }
}
.alert-success {
  border: 1px solid #00B200;
  border-radius: 0px;
  box-shadow: 0px 0px 15px rgba(0,178,0,0.2);
  color: #00B200;
  margin-bottom: 20px;
  padding: 15px;
  .close {
    right: 0;
  }
}

.has-error .control-label {
  color: var(--market-main);
}
.has-error .form-control {
  border-color: var(--market-main);
}

#globalHeader.navbar {
  margin-bottom: 0;
}
.embargoed {
  color: var(--market-main);
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .container.container--tablet {
    .col-xs-12.col-xs-12--tablet {
      padding: 0;
    }
  }
}
.close {
  opacity: .5;
  &:hover, &:focus {
    color: var(--market-main);
    opacity: 1;
  }
}

.tab-pane.channel-tab {
  &:not(.presskit-body) { background-color: @light-gray; }
  @media(min-width: @screen-sm-min) {
    height: 100%;
    overflow: hidden;
    padding: 0 30px 30px;
  }
  margin-bottom: 30px;
  .back-to-top-row {
    display: none;
    flex: 1;
    margin: 0;
    @media(min-width: @screen-sm-min) {
      margin: 0 -15px;
    }
    &:last-child {
      display: block;
    }
    .content-back-to-top-footer {
      width: 100%;
    }
  }
  .back-to-top-container {
    margin-top: 10px;
    @media(min-width: @screen-sm-min) {
      margin-top: 20px;
    }
    .back-to-top {
      margin-bottom: 15px;
      @media(min-width: @screen-sm-min) {
        margin-bottom: 0;
      }
    }
  }
  .photos-actions {
    float: left;
    padding: 8px 0;
    .photos-action {
      text-transform: uppercase;
      float: left;
      margin-right: 15px;
      i { color: var(--market-main); }
    }
  }
}
.fa.fa-twitter::before {
  content: "𝕏"!important;
}

//this should change the table headers for HON-24/25 to gray
.table>tbody>tr>th {
  background-color: #dddddd !important;
  border: 1px solid #000 !important;
}
/* ====================================================================== */

/* ==== STICKY NAVBAR =================================================== */
/* ====================================================================== */
body.site--honda {
  padding-top: 85px;
  @media(min-width: @screen-sm-min) {
    padding-top: 140px;
  }
}
body.site--honda.theme--honda-main-newsroom {
  padding-top: 50px;
  @media(min-width: @screen-sm-min) {
    padding-top: 100px;
  }
}
.navbar.site-navbar.sticky-navbar {
  top: 0;
  z-index: 9997;
}
.navbar.site-navbar.sticky-navbar.hide-nav {
  transform: translateY(-100px);
}
.navbar.site-navbar.sticky-navbar, .homepage-market-navbar-container {
  position: fixed;
  transition: transform 250ms;
  width: 100%;
}

.homepage-market-navbar-container {
  padding: 0;
  top: 50px;
  z-index: 998;
  @media(min-width: @screen-sm-min) {
    top: 100px;
  }
}
.homepage.homepage-market-navbar-container.hide-market-nav {
  transform: translateY(-140px);
}


/* ==== NAVBAR ========================================================== */
/* ====================================================================== */
.navbar.site-navbar {
  background-color: @white;
  border: none;
  height: 50px;
  @media(min-width: @screen-sm-min) {
    height: 100px;
  }

  .navbar-container {
    align-items: center;
    display: flex;
    justify-content: space-between;

    height: 50px;
    @media(min-width: @screen-sm-min) {
      height: 100px;
    }

    @media(max-width: @screen-xs-max) {
      margin: 0;
    }
    .nav-header {
      display: flex;
      flex: 1;
      h3 {
        font-weight: 900;
        margin-top: 15px;
        text-transform: uppercase;
      }
      .hamburger {
        background: transparent;
        border: none;
        display: block;
        float: none;
        margin-right: 10px;
        padding-left: 0;
        z-index: 4;
        .icon-bar {
          background-color: @black;
          height: 3px;
          width: 24px;
        }
      }
    }
    .navbar-actions {
      display: flex;
      justify-content: flex-end;
      font-size: 18px;
      flex: 1;
      margin: 0!important;
      a {
        padding-right: 7px;
        color: @black;
        &:hover, &:focus {
          color: var(--market-main);
        }

        i.fa {
          font-size: 20px;
        }
      }

      li:last-child {
        a {
          padding-right: 0;
        }
      }
      .account-dropdown-menu-btn {
        background: transparent;
      }
      .account-dropdown-menu {
        border: none;
        border-radius: 0;
        left: auto;
        right: 0px;
        top: 0px;
        text-align: right;
        li {
          a.close-account-dropdown-menu {
            color: @text-gray;
          }
          a {
            background: transparent;
            font-size: 18px;
            padding: 5px 20px 5px 5px!important;
            &:hover {
              color: var(--market-main);
            }
            i {
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}
.nav-action--search-link {
  padding-right: 0px!important;

  @media(min-width: @screen-sm-min) {
    padding-right: 7px!important;
  }
}

.clientBasketItemCount {
  font-size: 16px;
  position: relative;
  top: -1px;
}

.clientBasketItemCount--full {
  padding-left: 4px;
}

#side-navbar {
  background-color: @white;
  box-shadow: 0px 0px 20px -4px rgba(0,0,0,0.35);
  padding: 15px;
  position: absolute;
  top: 15px;
  left: -365px;
  transition: ease-in-out .25s;
  width: 350px;
  z-index: 9997;
  .close-nav {
    background: none;
    border: none;
    font-size: 18px;
    padding: 0;
    i {
      padding-right: 18px;
    }
  }
  ul {
    list-style: none;
  }
  ul.custom-navbar-menu {
    margin: 0;
    padding: 0;
    li {
      padding: 15px 0 0;
    }
    li.menu-item>a {
      font-weight: 700;
      padding-left: 40px;
      i {
        position: absolute;
        left: 15px;
        font-size: 20px;
      }
    }
    .menu-item__header {
      font-size: 14px;
      font-weight: 700;
      padding-left: 40px;
      i {
        position: absolute;
        left: 15px;
        font-size: 20px;
      }
    }
  }
  div.menu-divider {
    border-bottom: 1px solid @text-gray;
    padding-top: 15px;
  }
}
#side-navbar.active {
  left: 15px;
}

.theme--honda-main-newsroom {
  .nav-logo img {
    height: 72px;
    @media(min-width: @screen-sm-min) {
      height: 92px;
    }
  }
}
.theme--honda-automobiles-newsroom,
.theme--honda-marine-newsroom,
.theme--hondajet-newsroom {
  .nav-logo img {
    height: 40px;
    @media(min-width: @screen-sm-min) {
      height: 50px;
    }
  }
}
.theme--honda-racing-newsroom {
  .nav-logo img {
    height: 40px;
    @media(min-width: @screen-sm-min) {
      height: 60px;
    }
  }
}

.theme--hci-racing {
  .nav-logo img {
    height: 40px;
    @media(min-width: @screen-sm-min) {
      height: 60px;
    }
  }
}
.theme--powersports-newsroom {
  .nav-logo img {
    height: 40px;
    @media(min-width: @screen-sm-min) {
      height: 60px;
    }
  }
}
.theme--honda-collection {
  .nav-logo img {
    height: 50px;
    @media(min-width: @screen-sm-min) {
      height: 105px;
    }
  }
}
.theme--power-equipment-newsroom,
.theme--honda-engines-newsroom,
.theme--hci-power,
.theme--honda-electrification,
.theme--hci-electrification,
.theme--honda-corporate-newsroom {
  .nav-logo img {
    height: 18px !important;
    @media(min-width: @screen-sm-min) {
      height: 28px !important;
    }
  }
}

/* ====================================================================== */

/* ==== TABLET/MOBILE NAVBAR ============================================ */
/* ====================================================================== */
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .navbar-toggle { display: block; }
  .navbar.site-navbar {
    .desktop-nav {
      a {
        font-size: 12px;
        padding: 10px;
      }
    }
  }
}
.mobile-header {
  height: 66px;
  background-color: white;

  .mobile-logo-container {
    margin-left: 15px;
    margin-top: 13px;
    width: 78px;
    a {
      display: flex;
      align-items: center;
      h2 {
        font-weight: 700;
        margin-left: 10px;
        margin-top: 10px;
      }
    }
  }

  .mobile-logo-container__image {
    width: 100px;
    height: 40px;
  }

  .mobile-logo-container__label {
    font-size: 23px;
    font-weight: 700;
    margin-left: 10px
  }

  .mobile-search {
    float: right;
    font-size: 24px;
    margin-top: 16px;
    margin-right: 8px;
  }
  .hamburger {
    background: transparent!important;
    border: none;
    margin-top: 16px;
    span.icon-bar {
      background-color: @black;
      height: 3px;
    }
  }
}

.theme--honda-safety-newsroom {
  .nav-logo img {
    @media (max-width: @screen-xs-max) {
      height: 30px;
    }
  }
}
/* ====================================================================== */

/* ==== SEARCHBOX ======================================================= */
/* ====================================================================== */
.modal-dialog.search-modal-dialog {
  @media (max-width: @screen-xs-max) {
    width: 320px;
  }
  .modal-content {
    background-color: @light-gray;
    border: none;
    box-shadow: none;
    .modal-header {
      padding-top: 25px;
    }
    .modal-title {
      color: var(--market-main);
      display: flex;
      justify-content: center;
      text-transform: uppercase;
    }
    .table {
      tbody>tr>td {
        border: none;
        padding: 5px 0;
        width: 92px;
      }
      div.form-check {
        input {
          font-size: 20px;
        }
        .form-check-label {
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
    .modal-body {
      padding: 10px 30px 30px 30px !important;
    }
    form.searchbox {
      width: 100%;
      .searchbox-input {
        border: none;
        height: 50px;
        margin-bottom: 10px;
        padding: 0 15px;
      }
      .input-group-btn {
        .btn-secondary {
        }
      }
    }
    .modal-footer {
      border-top: 1px solid @line-gray;
      margin: 0 25px;
      padding: 25px 0;
      text-align: initial;
      .go-btn {
        background: transparent;
        border: none;
        display: flex;
        font-size: 20px;
        margin-left: auto;
        margin-right: auto;
        text-transform: uppercase;
        i {
          color: var(--market-main);
          margin-top: 4px;
          padding-left: 5px;
        }
      }
    }
  }
}
.modal-backdrop.in {
  opacity: .65;
}
.search-divisions {
  @media (max-width: @screen-xs-max) {
    margin-bottom: 10px;
  }
  h4 {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }
}
.search-divisions--list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  @media (min-width: @screen-sm-min) {
    height: 155px;
  }
}
.search-divisions--list-item {
  .checkbox {
    margin-bottom: 0;
    margin-top: 5px;
  }
}
.search-results-filter {


  .search-results-filter--option {
    @media (max-width: @screen-xs-max) {
      max-width: 220px;
      overflow: hidden;
    }
  }
}
.search-results-filter {
  label {
    text-transform: uppercase;
    @media (min-width: @screen-sm-min) {
      padding-right: 0;
    }
  }
  .search-dropdown-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    @media (min-width: @screen-sm-min) {
      padding-left: 0;
    }
  }
  .search-btn-dropdown {
    background-color: @white!important;
    box-shadow: none!important;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    width: 100%;
  }
  .search-results-filter--sort {
    border: none;
    border-radius: 0;
    padding: 10px;
    top: 40px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      margin-top: -15px;
      border: solid 10px transparent;
      border-bottom-color: transparent;
      border-bottom-color: #FFF;
      z-index: 1;
      left: calc(50% - 10px);
    }
    li { cursor: pointer; }
  }
  .search-calendar {
    display: flex;
  }
}
.search-form-btn {
  border: none;
  background-color: @market-main;
  color: @white;
  margin-top: 20px;
  padding: 5px 25px;
  text-transform: uppercase;
}
#suggestions {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  z-index: 9997;
  #suggested-title {
    background-color: @black;
    color: @white;
    padding: 10px 15px;
  }
  ul {
    background-color: @white;
    list-style: none;
    margin: 0 0 10px;
    padding: 10px 15px;
    li {
      padding: 5px 0;
      &:first-child {
        padding: 0 0 5px;
      }
      &:last-child {
        padding: 5px 0 0;
      }
    }
  }
}

/* ====================================================================== */

/* ==== MEDIA ALERT ===================================================== */
/* ====================================================================== */
.media-alert-container {
  align-items: center;
  background-color: @light-gray;
  display: flex;
  justify-content: flex-start;
  padding: 10px 15px;
  margin: 15px 15px 0 15px;

  flex-direction: column;

  @media(min-width: @screen-sm-min) {
    margin: 0 0 0 0;
    flex-direction: row;
  }

  i.fa-exclamation-triangle {
    color: var(--market-main);
    font-size: 16px;
    margin-right: 5px;
    position: relative;
    top: -1px;

    @media(min-width: @screen-sm-min) {
      font-size: 18px;
      top: -2px;
    }
  }

  h2 {
    color: var(--market-main);
    font-size: 20px;
    font-weight: 900;
    margin: 0;
    text-transform: uppercase;
    margin-right: 0;
    width: 100%;
    text-align: left;
    flex-shrink: 0;
    margin-bottom: 5px;

    @media(min-width: @screen-sm-min) {
      font-size: 24px;
      width: 20rem;
      margin-bottom: 0;
    }
  }

  h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    width: 100%;

    @media(min-width: @screen-sm-min) {
      font-size: 20px;
      font-weight: 600;
    }
  }
}
/* ====================================================================== */

/* ==== CAROUSEL ======================================================== */
/* ====================================================================== */



/*
Swiped from codepen referenced on https://stackoverflow.com/questions/18548731/bootstrap-3-carousel-fading-to-new-slide-instead-of-sliding-to-new-slide
*/
.carousel-fade .carousel-inner {
  background-color: #E6E6E6;
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

/*
WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
now override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


.homepage-carousel-container {
  #homepage-carousel {
    min-width: 100%;
    @media (min-width: @screen-sm-min) {
      margin-top: 0;
    }
    .item {
      height: 450px;
      figure {
        background-repeat: no-repeat;
        width: 60%;
        position: relative;
        margin-bottom: 10px;
        padding-top: 62%;
        overflow: hidden;
        @media (max-width: @screen-sm-max) {
          background-position: center center;
          background-size: cover;

          margin-bottom: 0;
          width: 100%;
        }

        @media (min-width: @screen-md-min) {
          background-position: center center;
          background-size: cover;
          padding-top: 0;
          margin-bottom: 0;
          height: 450px;
        }
      }
      .homepage-carousel-caption {
        background-color: @light-gray;
        padding: 30px;
        position: relative;
        width: 40%;
        @media (max-width: @screen-sm-max) {
          background-color: @black;
          padding: 10px;
          width: 100%;
        }
        h2 {
          margin: 0 0 5px 0;
          font-size: 16px;
          font-weight: bold;
          a {
            color: @white;
          }
          @media (min-width: @screen-md-min) {
            font-weight: bold;
            font-size: 30px;
            margin-bottom: 20px;
            a {
              color: var(--market-main);
            }
          }
        }
        .homepage-carousel-caption__date {
          font-size: 14px;
          color: #7a7a7a;
          text-transform: uppercase;

          @media(min-width: @screen-md-min) {
            margin-bottom: 7px;
            font-size: 18px;
            color: black;
          }
        }
        p {
          font-size: 18px;
          @media (max-width: @screen-md-max) {
            display: none;
          }
        }
      }
    }
    .item.active {
      display: flex;
      @media (max-width: @screen-sm-max) {
        flex-wrap: wrap;
        height: auto;
      }
    }
    .carousel-control {
      background: transparent;
      padding-top: 30%;
    }
    @media (max-width: @screen-sm-max) {
      .left.carousel-control {
        padding-left: 15px;
      }
      .right.carousel-control {
        padding-right: 15px;
      }
    }
    .carousel-actions {
      display: none;
      position: absolute;
      right: 0;
      z-index: 15;
      bottom: 20px;
      width: 40%;

      @media(min-width: @screen-md-min) {
        display: flex;
      }
    }
  }
}
/* ====================================================================== */

/* ==== CAROUSEL ACTIONS ================================================ */
/* ====================================================================== */
.carousel-actions {
  display: flex;
  justify-content: center;
  height: 34px;
  width: 100%;
  .custom-carousel-control {
    font-size: 16px;
    line-height: 31px;

    color: @line-gray;
    &:hover, &:focus {
      color: var(--market-main);
    }

    @media(min-width: @screen-md-min) {
      font-size: 28px;
    }
  }
  .carousel-indicators-custom {
    list-style: none;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0;
    margin-top: 9px;

    // Turn off these styles that boostrap uses.
    position: static;
    width: auto;
    margin-left: 0;

    li {
      border: 2px solid @line-gray;
      border-radius: 10px;
      display: inline-block;
      margin-left: 2px;
      margin-right: 2px;
      text-indent: -999px;
      height: 14px;
      width: 14px;
      margin-top: 0!important;
    }
    li.active {
      margin-top: 0!important;
      position: relative;
      background-color: @line-gray;
    }
  }
}

/* ====================================================================== */

/* ==== FOOTER ========================================================== */
/* ====================================================================== */

.site-footer-container {
  width: 100%;

  .site-footer-legal__outer-container {
    background-color: var(--market-main);
  }
  .site-footer-legal {
    color: @white;
    height: auto;
    padding: 5px 15px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 12px;

    & a {
      color: @white;
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: black;
      }
    }

    @media(min-width: @screen-sm-min) {
      flex: 1;
      font-size: 14px;
    }

    .quick-links {
      flex: 1;
      text-align: right;
      a {
        color: @white;
        margin-left: 20px;
      }
    }

  }
}

.site-footer-container--links {
  background-color: #EBEBEB;
  transition: height ease-in-out 250ms;
  overflow: scroll;
  &.is-expanded {
    height: 600px;
    z-index: 2;
    .site-footer-links {
      border-top: solid 1px #7a7a7a;
    }

    #footer-body {
      display: flex;
    }
  }
}

.site-footer-links {
  background-color: @light-gray;
  height: 95px;
  transition: border-color 250ms;

  @media(min-width: @screen-sm-min) {
    height: 50px;
  }

  .site-footer-links-container {
    display: flex;
    align-items: center;
    height: 100%;
    @media(max-width: @screen-xs-max) {
      display: block;
    }
    .quick-links {
      flex: 1;
      @media(max-width: @screen-xs-max) {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
      a {
        color: @line-gray;
        font-size: 14px;
        margin-left: 10px;
        margin-right: 10px;
        text-transform: uppercase;

        &:hover, &:focus {
          color: var(--market-main);
        }

        @media(min-width: @screen-sm-min) {
          &:first-child {
            margin-left: 0;
          }
        }

        @media(min-width: @screen-md-min) {
          font-size: 16px;
          margin-right: 10px;
        }
      }
    }
    .social-media {
      @media(max-width: @screen-xs-max) {
        display: flex;
        justify-content: center;
      }
      .social-btn {
        background: transparent;
        border: none;
        color: @line-gray;
        font-size: 20px;

        @media(min-width: @screen-sm-min) {
          &:last-child {
            padding-right: 0;
          }
        }

        &:hover, &:focus {
          color: var(--market-main);
        }
      }
    }
  }
}


#footer-body {
  display: none;
  justify-content: space-between;
  .footer-col {
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
    .heading {
      font-weight: 700;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    ul {
      list-style: none;
      padding-left: 0;
      li {
        padding: 0;
        ul li {
          font-size: .9em;
        }
      }
    }
    .channel-link {
      font-size: 1.25em;
    }
  }
}



.back-to-top, .load-more-btn {
  color: @black;
  transition: ease-in-out .25s;
  text-transform: uppercase;
  &:hover, &:focus {
    color: var(--market-main);
  }
  i {
    color: var(--market-main);
    font-size: 16px;
  }
}
.load-more-btn {
  float: right;
}
.back-to-top-footer {
  margin: 15px 0 50px 0;
  text-align: center;

  // Homepage modifier
  &--homepage {
    margin: 5px 0 5px 0;

    @media(min-width: @screen-sm-min) {
      margin: 15px 0 15px 0;
    }
  }
}
div.col-5-back-to-top {
  padding-right: 0;
}
div#back-to-top { display: inline; }
#site-subscribe-footer {
  .homepage-section-border {
    margin-bottom: 20px;
  }
  .site-subscribe-banner {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 136px;
    overflow: hidden;
    width: 100%;
  }
  .site-subscribe-form {
    border: 1px solid @black;
    padding: 15px;
    @media (max-width: @screen-sm-max) {
      margin-top: 20px;
    }
    .site-subscribe-header {
      margin-top: 0;
      font-weight: 100;
    }
    .input-group {
      input {
        border-color: @black;
      }
      #subscribe-addon {
        background-color: @black;
        border-color: @black;
        border-radius: 0;
        color: @white;
        cursor: pointer;
        transition: ease-in-out .25s;
        &:hover {
          background-color: @white;
          color: @black;
        }
      }
    }
  }
}
/* ====================================================================== */


.container--zero-padding-xs {
  @media(max-width: @screen-xs-max) {
    // On mobile, stretch all containers across the screen.
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;
  }
}

.container-sm {
  @media(min-width: @screen-sm-min) {
    .container-fixed();

    @media (min-width: @screen-sm-min) {
      width: @container-sm;
    }
    @media (min-width: @screen-md-min) {
      width: @container-md;
    }
    @media (min-width: @screen-lg-min) {
      width: @container-lg;
    }
  }
}


/* ==== HOMEPAGE ======================================================== */
/* ====================================================================== */
.homepage {

  &.container {
    margin-bottom: 10px;

    @media(min-width: @screen-sm-min) {
      margin-bottom: 80px;
    }

    .container--zero-padding-xs();
  }

  .homepage-flex-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media (min-width: @screen-sm-min) {
      flex-direction: row;
    }
  }
  .homepage-section {

    .homepage-section-header {
      text-align: center;
      h2 {
        color: var(--market-main);
        font-weight: 900;
        margin-bottom: 10px;
        margin-top: 0px;
        text-transform: uppercase;
        font-size: 18px;

        @media (min-width: @screen-sm-min) {
          font-weight: 700;
          font-size: 30px;
        }
      }

      &--with-controls {
        display: flex;
        @media(min-width: @screen-sm-min) {
          height: 70px;
        }
      }

      &--no-subtitle {
        @media(min-width: @screen-sm-min) {
          margin-bottom: 15px;
        }
      }
    }
    .homepage-section-container {
      background-color: @light-gray;
      padding: 10px 0;

      @media (min-width: @screen-sm-min) {
        padding: 30px;
      }

      .more-btn-container {
        text-align: center;
        margin-top: 10px;

        @media(min-width: @screen-sm-min) {
          margin-top: 30px;
        }
        ul.dropdown-menu-center {
          left: 50% !important;
          right: auto !important;
          text-align: center !important;
          transform: translate(-50%, 0) !important;
        }
        .more-btn-dropdown-container-mobile {
          ul.dropdown-menu {
            border: none;
            border-radius: 0;
          }
        }
        a {
          font-size: 16px;
          text-transform: uppercase;
          i {
            color: var(--market-main);
          }
        }
      }

    }
  }
}
/* ====================================================================== */

/* ==== LATEST NEWS ===================================================== */
/* ====================================================================== */
.homepage {
  #latest-news {
    .homepage-section-header {
      .homepage-section__display-style-toggle-container {
        flex: 1;
        text-align: left;
        a {
          font-size: 20px;
          margin-right: 5px;
        }
      }
      .homepage-section__title {
        flex: 1;
      }
      .homepage-section__action-buttons {
        flex: 1;
        text-align: right;
      }
      .more-btn-dropdown-container-desktop {
        margin-top: -3px;
        ul.dropdown-menu {
          border: none;
          border-radius: 0;
          left: -40px;
          margin-top: 10px;
          padding: 6px 0;
          text-align: left;
          @media(min-width: @screen-md-min) {
            text-align: center;
          }
          &:before {
            display: none;
            position: absolute;
            top: -12px;
            left: ~"calc(50% - 12px)";
            border-right: 12px solid transparent;
            border-bottom: 12px solid @white;
            border-left: 12px solid transparent;
            border-bottom-color: @white;
            content: '';

            @media(min-width: @screen-sm-min) {
              display: inline-block;
            }
          }
          li a {
            color: @black;
            transition: none;
            &:hover, &:focus {
              background-color: var(--market-main);
              color: @white;
            }
          }
        }
      }
      .more-btn {
        box-shadow: none;
        margin-left: 10px;
        font-size: 16px;
        text-transform: uppercase;
        i {
          color: var(--market-main);
        }
      }
    }
  }
  .division-more-btn {
    color: @black;
    display: block;
    font-size: 16px;
    margin-top: 0;
    text-align: center;
    text-transform: uppercase;
    i {
      color: var(--market-main);
    }
    &:hover {
      color: var(--market-main);
    }
    @media(min-width: @screen-sm-min) {
      margin-top: 30px;
    }
  }
}

.saved-basket-item {
  color: var(--market-main)!important;
}

.release-grid {
  .release-grid-indicator { color: var(--market-main); }
  .release-list-indicator { color: @line-gray; }
  .release-item-thumbnail-image__list { display: none; }
}

.release-list {
  .release-list-indicator { color: var(--market-main); }
  .release-grid-indicator { color: @line-gray; }
  .release-item-thumbnail-image__grid { display: none; }
}

.release-grid-mixin(@sm-to-md: 2, @lg: 4) {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 0;
  flex-direction: row;

  .release-item {
    padding: 0!important;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }

    // * -> sm: 1-wide
    width: 100%;

    a {
      width: 50%;
    }

    // sm <-> md
    @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
      border-left: solid 15px #EBEBEB;
      border-right: solid 15px #EBEBEB;
      flex-direction: column;
      margin-bottom: 30px;

      width: calc(100% / @sm-to-md);

      &:nth-last-child(-n+@{sm-to-md}) {
        margin-bottom: 0;
      }
      &:nth-child(@{sm-to-md}n) {
        border-right: none;
      }
      &:nth-child(@{sm-to-md}n+1) {
        border-left: none;
      }

      a {
        width: 100%;
      }
    }

    @media (min-width: @screen-lg-min) {
      border-left: solid 15px #EBEBEB;
      border-right: solid 15px #EBEBEB;
      flex-direction: column;
      margin-bottom: 30px;

      width: calc(100% / @lg);
      &:nth-last-child(-n+@{lg}) {
        margin-bottom: 0;
      }
      &:nth-child(@{lg}n) {
        border-right: none;
      }
      &:nth-child(@{lg}n+1) {
        border-left: none;
      }

      a {
        width: 100%;
      }
    }

    summary {
      display: none;
    }

    .release-list-actions-container {
      display: none;
    }

    .row {
      margin: unset!important;
    }

    .col-xs-12 {
      padding: unset!important;
    }

    div.release-information {
      width: 50%;
      padding: 10px;

      @media(min-width: @screen-sm-min) {
        width: 100%;
      }

      .release-information-row {
        display: grid;

        grid-template-columns: 100%;
        grid-template-rows: auto auto;

        grid-template-areas: "title"
                             "pub-date";

        @media(min-width: @screen-sm-min) {
          grid-template-areas: "pub-date"
                               "title";
        }
      }

      .pub-date {
        grid-area: pub-date;
        color: @black!important;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        text-transform: uppercase;
        font-size: 12px;
        @media(min-width: @screen-sm-min) {
          margin-top: inherit;
          margin-bottom: 5px;
          font-size: 14px;
        }
        .release-grid__actions {
          display: flex;
          a {
            font-size: 16px;
            margin-left: 5px;
            margin-top: -2px;
          }
        }
      }

      .content-title-container {
        grid-area: title;
      }

      .summary-container {
        display: none;
      }
    }
    div.release-information.embargoed {
      .pub-date {
        color: @state-danger-text!important;
        text-transform: capitalize!important;
      }
    }

    figure {
      width: 100%;
    }

    .content-title {
      margin: 0;
      a {
        color: black;
        font-size: 14px;

        @media(min-width: @screen-sm-min) {
          font-size: 16px;
        }
      }
    }

    // Hide the img we use for list layout
    .release-list__photo {
      display: none;
    }

  }
}

.release-list-mixin {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;

  .release-item {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;

    & > a {
      flex: 0.35;
      width: unset;

      figure {
        width: 100%;
        height: 100%;
      }
    }

    & > div.release-information {
      flex: 0.65;
      width: unset;
    }


    img {
      @media (max-width: @screen-xs-max) {
        width: 100%;
      }
    }


    .content-title {
      a {
        @media(min-width: @screen-sm-min) {
          color: var(--market-main);
          font-weight: bold;
          font-size: 20px;
        }

        @media(min-width: @screen-md-min) {
          font-size: 22px;
        }
      }
    }

    .release-information.embargoed {
      .pub-date {
        color: @state-danger-text!important;
        text-transform: capitalize;
      }
    }

    .pub-date {
      color: @black;
      text-transform: uppercase;
      .release-grid__actions {
        display: none;
      }
    }

    // Hide the cropped figure+bg image photo we use for grid layout
    .release-grid__photo {
      display: none;
    }

  }
}

.release-grid .release-container {
  // No matter what, if being asked to display a release-grid, do it.
  .release-grid-mixin(2, 4);
}

.release-list .release-container {
  // If being asked to display a release-list on sm or lower, display a release-grid instead.
  @media(max-width: @screen-xs-max) {
    .release-grid-mixin(2, 4);
  }

  // Otherwise, display the release-list style
  @media(min-width: @screen-sm-min) {
    .release-list-mixin();
  }
}
/* ====================================================================== */

/* ==== PERSPECTIVES ==================================================== */
/* ====================================================================== */
.homepage {
  #perspectives {
    .homepage-section-container {
      .homepage-section-header {
        h2 {
          margin-bottom: 0;
        }
        h3 {
          color: @line-gray;
          margin: 5px 0 10px 0;
          font-size: 12px;

          @media(min-width: @screen-sm-min) {
            margin-bottom: 30px;
            font-size: 14px;
          }
        }
      }
    }
    .release-container {
      .release-grid-mixin(3, 3);
    }
  }
}
/* ====================================================================== */

/* ==== SOCIAL & TRENDING =============================================== */
/* ====================================================================== */
.homepage {
  #social {
    flex: 1;

    @media (min-width: @screen-sm-min) {
      flex: 0.3;
      margin-bottom: 0;
    }
  }

  #trending {
    flex: 1;

    .release-container {
      .release-grid-mixin(3, 3);
    }

    .views {
      color: @line-gray;
      margin-top: 15px;
    }
  }
}
/* ====================================================================== */

/* ==== HOMEPAGE PHOTOS/VIDEOS ========================================== */
/* ====================================================================== */
.homepage {
  .homepage-section-container {
    position: relative;
  }
  #homepage-photo-search, #homepage-video-search {
    width: 50%;
    flex: 1;
    figure {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      padding-top: 62%;
      position: relative;
      margin-bottom: 10px;
    }
    .media-search-overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 70%;
      //z-index: 2;

      @media(min-width: @screen-md-min) {
        width: 80%;
      }

      h2 {
        color: @white;
        margin: 0 0 30px;
        text-transform: uppercase;
        text-shadow: 0 0 6px rgba(0,0,0,0.75);
        font-weight: bold;

        font-size: 22px;
        @media(min-width: @screen-md-min) {
          font-size: 30px;
        }
      }
      .media-overlay-searchbox {
        margin: 0 0 30px;
        input {
          background-color: rgba(255,255,255,0.85);
          border: none;
          border-radius: 0;

          height: 40px;
          padding: 12px;
          font-size: 16px;

          &::placeholder {
            font-style: italic;
          }

          @media(min-width: @screen-md-min) {
            height: 60px;
            padding: 10px 25px;
          }

        }
        button {
          border: none;
          border-radius: 0;
          color: var(--market-main);
          font-size: 18px;
          background-color: rgba(255,255,255,0.85);

          height: 40px;
          padding: 5px 15px;

          @media(min-width: @screen-md-min) {
            height: 60px;
            padding: 10px 20px;
          }
        }

        &--button-icon {
          font-size: 16px;

          @media(min-width: @screen-md-min) {
            font-size: 28px!important;
          }
        }
      }
      .browse-all-media {
        margin: 0 0 30px;
        color: White;
        font-size: 18px;
        text-transform: uppercase;
        i {
          color: var(--market-main);
        }
      }
    }
  }
  #homepage-photo-search {
    padding-right: 15px;
  }
  #homepage-video-search {
    padding-left: 15px;
  }
}

.content-video {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
  padding-top: 30px;
}

.content-video iframe,
.content-video object,
.content-video embed {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* ====================================================================== */

/* ==== EMBARGO CHANNEL DESCRIPTION ===================================== */
/* ====================================================================== */
.embargo-channel-description {
  border: solid 15px white;
  background-color: #454545;
  padding: 15px;
  color: white;
  font-size: 1.4rem;

  @media(min-width: @screen-sm-min) {
    border-color: #ebebeb;
    border-width: 30px;
    border-bottom: none;
    padding: 30px;
    font-size: 2rem;
  }
}
/* ====================================================================== */

/* ==== CONTENT TYPE TAB BAR ============================================ */
/* ====================================================================== */
#content-type-tab-bar {
  background-color: @white;
  padding: 0px;
  position: relative;
  margin: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media(min-width: @screen-sm-min) {
    background-color: @light-gray;
    padding: 0 30px;
  }
  .nav-tabs {
    border: none;
    .content-type {
      text-align: center;
      z-index: 2;
      a {
        background-color: @light-gray;
        border: 1px solid @white;
        border-radius: 0;
        cursor: pointer;
        color: var(--market-main);
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 30px;
        padding: 10px;
        text-transform: uppercase;
        margin-right: 0;
        @media(min-width: @screen-sm-min) {
          border: none;
          color: #7a7a7a;
          padding: 30px 6px;
        }
        @media(min-width: @screen-md-min) {
          padding: 30px 15px;
        }
        i {
          font-size: 20px;
          position: relative;
          @media(min-width: @screen-sm-min) {
            font-size: 25px;
          }
        }
        .content-type-span {
          display: none;
          @media (min-width: @screen-sm-min) {
            display: block;
          }
        }
      }
    }
    .content-type:first-child a {
      border-left: none;
      @media(min-width: @screen-sm-min) {
        padding-left: 0;
      }
    }
    .content-type.active a {
      background-color: var(--market-main);
      border-radius: 0;
      color: @white;
      @media(min-width: @screen-sm-min) {
        background-color: @light-gray;
        color: var(--market-main);
      }
      .content-type-span {
        @media (min-width: @screen-sm-min) {
          border-bottom: 5px solid var(--market-main);
        }
      }
    }
  }

  &.no-results .custom-media-actions{
    margin-top: 0;
  }

  .custom-media-actions {
    #refineSearch {
      margin-right: 2px;
      @media (min-width: @screen-sm-min) {
        margin-right: 0;
      }
    }
    #filterForm {
      display: flex;
    }
    .custom-btn-group {
      #daterange-desktop, .dropdown-toggle {
        align-items: center;
        background-color: @light-gray;
        border-left: none;
        border-right: none;
        border-top: 1px solid @white;
        border-bottom: 1px solid @white;
        box-shadow: none;
        color: #7A7A7A;
        font-size: 14px;
        line-height: initial;
        padding: 10px;
        text-transform: uppercase;

        &.is-active, &.open {
          background-color: var(--market-main);
          color: @white;
          &:focus {
            color: @white;
          }

          @media (min-width: @screen-sm-min) {
            background-color: @light-gray;
            color: var(--market-main);
          }
        }

        @media (min-width: @screen-sm-min) {
          background-color: transparent;
          border: none;

          display: flex;
          flex-direction: column;
          padding: 30px 5px;
          line-height: 30px;
          &:hover {
            color: var(--market-main);
          }
        }
        i.fa {
          font-size: 20px;
          @media (min-width: @screen-sm-min) {
            font-size: 25px;
          }
        }
      }
    }
    .custom-btn-group.open  {
      ul.site-dropdown-menu {
        border: none;
        border-radius: 0;
        li a {
          color: @black;
          padding: 3px 12px;
          &:active, &:hover {
            background-color: @white;
            color: var(--market-main);
          }
        }
      }
      ul#sort-options {
        left: -50px;
        right: 0;
        top: 51px;
        margin: 0;
        min-width: 115px;
        @media (max-width: @screen-xs-max) {
          left: -90px;
          right: 0;
        }

        @media (min-width: @screen-sm-min) {
          top: 83px;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          margin-top: -15px;
          left: ~"calc(86% - 10px)";
          border: solid 10px transparent;
          border-bottom-color: #FFF;
          z-index: 1;

          @media (min-width: @screen-sm-min) {
            left: ~"calc(66% - 10px)";
          }
        }
      }
    }
  }
}
.daterangepicker.show-calendar.opensleft:after {
  right: 48px;
}
/* ====================================================================== */

/* ==== DATERANGE ======================================================= */
/* ====================================================================== */
#daterange-desktop__dropdown {
  border-radius: 0;
  border: none;
  padding: 10px;

  @media(min-width: @screen-sm-min) {
    top: 83px;
    left: -26px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    margin-top: -15px;
    border: solid 10px transparent;
    border-bottom-color: #FFF;
    z-index: 1;

    left: ~"calc(87% - 10px)";

    @media(min-width: @screen-sm-min) {
      left: ~"calc(50% - 10px)";
    }
  }

  .refine-search-quick-filter {
    background-color: @light-gray;
    border: none;
    color: @black;
    margin-bottom: 10px;
    padding: 5px 10px;
    text-align: left;
    width: 100%;
    transition: none;
    &--active, &:hover {
      background-color: var(--market-main);
      color: @white;
    }
  }
  .refine-search-quick-filter.custom-range {
    background-color: var(--market-main);
    color: @white;
  }
  .input-group {
    margin-bottom: 10px;
    .form-control {
      border-color: @light-gray;
      box-shadow: none;
    }
    .input-group-addon {
      border-color: @light-gray;
      border-radius: 0;
      padding: 8px;
      cursor: pointer;
    }
  }
  .btn-group {
    width: 100%;
    .apply-date-filter, .clear-date-filter {
      border-radius: 0;
      width: 50%;
    }
  }
}
/* ====================================================================== */

/* ==== RELEASE SHOW CONTENT TYPE TAB BAR =============================== */
/* ====================================================================== */

#content-type-tab-bar.release-content-type-tab-bar {
  background-color: @white;
  margin: 15px 0;
  padding: 0;
  .nav-tabs .content-type a {
    @media(min-width: @screen-sm-min) {
      background-color: @white;
    }
  }
  .nav-tabs .content-type.active>a {
    @media(min-width: @screen-sm-min) {
      background-color: @white;
    }
  }
}
/* ====================================================================== */

/* ==== MODALS ========================================================== */
/* ====================================================================== */
.modal {
  text-align: center;
}

@media(min-width: @screen-sm-min) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  margin: 50px auto!important;
  @media(min-width: @screen-sm-min) {
    margin: 30px auto!important;
  }
}



div.modal-body {
  div#prev, div#next {
    color: @text-gray;
    cursor: pointer;
  }
}
.modal-dialog-preview {
  .photo-modal-image {
    max-height: 768px;
  }
  .content-title h2 {
    color: @black;
    font-size: 28px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .modal-footer {
    padding: 0 15px 15px;
    border-top: none;
  }
}
.modal-dialog {
  .modal-content {
    background-color: @dark-gray;
    border-radius: 0;
    .modal-header {
      border: none;
      button.close {
        color: @black;
        font-weight: normal;
        opacity: 1;
        text-shadow: none;
        text-transform: uppercase;
        &:hover, &:focus {
          color: @market-hover;
        }
        i {
          font-size: 20px;
        }
      }
    }
    .modal-body {
      padding: 0 15px;
    }
  }
}
#media-modal {
  z-index: 9998;
  .modal-dialog {
    .modal-content {
      .modal-body {
        .photo-preview-container, .video-preview-container {
          padding: 0;
        }
        .content-title h2 {
          color: @white;
        }
        .content-caption {
          color: #b3b3b3;
        }
        img {
          margin-left: auto;
          margin-right: auto;
        }
        .modal-actions-container {
          display: flex;
          flex-direction: column;
          position: absolute;
          right: 0;
          top: 0;
          .photo-pagination, .site-btn {
            background: rgba(0, 0, 0, 0.5);
            border: none;
            color: @white;
            font-size: 20px;
            margin-bottom: 3px;
            opacity: .5;
            padding: 15px 20px;
            text-align: center;
            transition: ease-in-out .25s;
            z-index: 9998;
            &:hover, &:focus {
              background: rgba(0, 0, 0, 1);
              color: var(--market-main);
              opacity: 1;
            }
            @media (max-width: @screen-xs-max) {
              font-size: 14px;
            }
          }
          .dropdown-menu {
            border-radius: 0;
            left: auto;
            right: 0;
          }
        }
        .photo-pagination.photo-pagination-prev {
          &.disabled {
            color: rgba(255,255,255,0.25);
            cursor: not-allowed!important;
          }
        }
        .photo-pagination.photo-pagination-next {
          &.disabled {
            color: rgba(255,255,255,0.25);
            cursor: not-allowed!important;
          }
        }
      }
    }
  }
}
/* ====================================================================== */

/* ==== LICENSE MODAL =================================================== */
/* ====================================================================== */
#licenseModal {
  z-index: 9999;
}
#licenseModal .modal-dialog {
  .modal-content {
    background-color: @white;
    .modal-header {
      h3 {
        font-size: 20px;
        text-transform: uppercase;
      }
    }
    .btn-primary {
      background-color: var(--market-main);
      border: none;
      border-radius: 0;
    }
  }
}
/* ====================================================================== */

/* ==== CHANNELS ======================================================== */
/* ====================================================================== */
.market-navbar {
  background-color: var(--market-main);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
  @media(min-width: @screen-sm-min) {
    flex-direction: row;
    padding: 0;
  }
  .dropdown-submenu {
    position: relative;
  }
  .dropdown-submenu>.dropdown-menu {
    border: none;
    border-radius: 0;
    margin-left: 0px!important;
    margin-top: 0px!important;
    left: 100%;
    right: auto;
    top: 0;
  }
  .dropdown-submenu:hover>.dropdown-menu {
    display: block;
  }
  .dropdown-submenu>.dropdown-menu:before {
    display: none!important;
  }
  ul.nav {
    .navbar-nav {
      margin-top: 0;
      margin-bottom: 0;

      @media(min-width: @screen-md-min) {
        margin-top: inherit;
        margin-bottom: inherit;
      }
    }
    li.dropdown {
      a {
        background: transparent;
        color: @white;
        padding: 10px;
        text-transform: uppercase;
      }
      ul.dropdown-menu {
        border-radius: 0;
        margin-top: 10px;
        padding: 0;
        text-align: left;
        @media(min-width: @screen-md-min) {
          text-align: center;
        }
        &:before {
          display: none;
          position: absolute;
          top: -12px;
          left: ~"calc(50% - 12px)";
          border-right: 12px solid transparent;
          border-bottom: 12px solid @white;
          border-left: 12px solid transparent;
          border-bottom-color: @white;
          content: '';

          @media(min-width: @screen-sm-min) {
            display: inline-block;
          }
        }
        li a {
          color: @black;
          transition: none;
          &:hover, &:focus {
            background-color: var(--market-main);
            color: @white;
          }
        }
      }
    }
  }
}
.homepage-market-navbar-container {
  margin-bottom: 0!important;
}
.homepage-market-navbar-header {
  height: 35px;line-height: 35px;
}
.homepage-market-navbar-button {
  border: 0;
  background-color: transparent;
  color: white;
  position: absolute;
  height: 30px;
  font-size: 26px;
  padding: 0 0 0 4px;
}
.homepage-market-navbar-title {
  color: white;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
}

.market-channel__container--level1 {
  display: block;
}
@media(max-width: @screen-xs-max) {
  .market-channel__container--level1 {
    display: none;
    margin-top: 0;
    margin-bottom: 0;
    &.is-visible {
      display: block;
    }
  }
  .market-channel__container--level2 {
    background-color: var(--mobile-nav-level2-bg)!important;
    a {
      color: var(--mobile-nav-level2-fg)!important;
      padding-left: 20px!important;
    }
  }
  .market-channel__container--level3 {
    background-color: var(--mobile-nav-level3-bg)!important;
    a {
      color: var(--mobile-nav-level3-fg)!important;
      padding-left: 40px!important;
    }
  }
}
.channel-banner-fixed {
  margin-bottom: 65px!important;
}
.channel-custom-dropdown.open {
  @media (max-width: @screen-xs-max) {
    position: static;
    ul.site-dropdown-menu {
      width: 100%;
    }
  }
}
#channel-banner {
  min-height: 190px;
  margin-top: 100px;
  background-color: black;
  margin-bottom: 25px;
  padding-top: 25px;
  #social-filter {
    cursor: pointer;
    margin-top: 50px;
    color: white;
    border: 1px solid white;
    width: 135px;
    height: 40px;
    text-align: center;
    padding-top: 10px;
    i.fa {
      margin-left: 5px;
      position: relative;
      top: -3px;
    }
  }
  ul#social-subchannels {
    width: 135px;
    list-style: none;
    padding: 0px;
    float: right;
    li {
      width: 135px;
      height: 40px;
      border: 1px solid white;
      text-align: center;
      padding-top: 10px;
    }
  }
  background-size: cover!important;
  display: flex;
  display: -ms-flexbox;
  h1#channel-title {
    color: @white;
    line-height: 108px;
    font-weight: lighter;
    padding-left: 13px;
    text-shadow: 0 2px 7px rgba(0,0,0,0.3);
  }
  @media (max-width: @screen-xs-max) {
    div { padding-left: 0px; }
    height: 4em;
    h1#channel-title {
      padding-left: 6px!important;
      font-size: 1.7em;
      margin-top: 12px;
      line-height: 30.8px;
    }
  }
  img {
    width: 100%;
  }
}
ul.secondary-nav {
  border: none;
  li.active {
    border-bottom: 5px solid black!important;
    a {
      border-bottom-color: transparent!important;
      border-top-color: transparent!important;
      border-left-color: transparent!important;
      border-right-color: transparent!important;
      color: @black;
    }
  }
  li {
    margin-left: 10px;
    margin-right: 10px;
    a {
      border-color: transparent!important;
      background-color: transparent!important;
      color: #555555;
      padding-bottom: 3px;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
      &:hover, &:focus {
        color: @market-hover;
      }
    }
  }
}
// Embargoed Channel Show Page
div.embargoed-channel {
  div.banner-component {
    width: 100%;
    h1.release-banner-title {
      text-align: center;
    }
    div.channel-embargo-notice {
      background-color: #CC0000;
      padding: 20px;
      text-align: center;
      color: white;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  div.custom-media-actions { display: none; }
  div.release-list-actions-container {
    a.basket-action-visible, a.site-btn.facebook, a.site-btn.twitter, a.site-btn.linkedin { display: none!important; }
  }
}
/* ====================================================================== */

/* ==== RELEASES ======================================================== */
/* ====================================================================== */
.tab-content {
  .release-item {
    position: relative;
    background-color: @white;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    padding: 10px;
    figure.release-photo {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 140px;
      overflow: hidden;
      @media (max-width: @screen-xs-max) {
        height: 25vw;
        width: 100%;
      }
    }
    img.release-photo {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    .release-photo.embargoed {
      border: solid 1px var(--market-main);
      padding: 5px;
    }
    .release-information {
      @media (max-width: @screen-xs-max) {
        height: auto;
      }
      .content-type-icon {
        font-size: 22px;
        margin-right: 5px;
      }
      .fa-facebook.content-type-icon {
        color: #3b5998;
      }
      .fa-twitter.content-type-icon {
        color: #000000;
      }
      .fa-youtube-play.content-type-icon {
        color: #bb0200;
      }
      summary {
        margin-top: 5px;
      }
      .release-summary-continue {
        size: 14px;
        margin-left: -4px;
      }
      .release-list-actions-container {
        min-height: 30px;
        .release-list-actions {
          @media (min-width: @screen-xs-max) {
            margin-top: 10px;
          }
          .site-btn {
            color: @black;
            margin-right: 5px;
            transition: ease-in-out .25s;
            &:focus, &:hover {
              color: var(--market-main);
            }
            i {
              font-size: 20px;
            }
          }
        }
      }
      div.presskit-member {
        background-color: @light-gray;
        padding: 5px;
        margin-top: 3px;
        margin-bottom: 3px;
        display: inline-block;
      }
    }
    .release-information.embargoed {
      .content-title a {
        color: var(--market-main)!important;
        &:before {
          font-family: fontawesome;
          content: "\f05e";
          display: inline-block;
          padding-right: 5px;
        }
      }
      summary {
        color: var(--market-main)!important;
      }
      .published-at {
        color: var(--market-main)!important;
      }
      .release-list-actions {
        display: none;
      }
    }
  }
  .release-item:last-child {
    //margin-bottom: 5px;
  }
}
#release-tab-content {
  .tab-pane.channel-tab:not(.presskit-body) {
    background-color: transparent;
    @media (min-width: @screen-sm-min) {
      background-color: @light-gray;
      padding: 4px 30px 30px 30px;
    }
  }
}

// Hide redundant back-to-tops in channel list presentations
.channel-presentation-style--list .paginated-content-container .content-back-to-top-footer {
  display: none;
}
.content-back-to-top-footer {
  a.back-to-top {
    float: left;
    margin-bottom: 30px;
  }
}
.nav {
  .release-action {
    float: right;
    a {
      padding: 0;
      margin-left: 10px;
      text-transform: uppercase;
      &:focus, &:hover {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
}
div.publication {
  margin-bottom: 1em;
  .published-at {
    &:before {
      margin-right: 5px;
    }
    time {
      font-weight: bold;
    }
  }
  span.dateline {
    font-weight: normal;
  }
}
.header-banner-container {
  background-color: white;
  background-position: center;
  @media (min-width: @screen-sm-min) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (min-width: @screen-lg-min) {
    min-height: var(--banner-min-height);
  }
}
.header-banner {
  position: relative;
  @media (min-width: @screen-lg-min) {
    min-height: var(--banner-min-height);
  }
  picture {
    filter: brightness(50%);
    min-height: 138px;
    background-color: black;
    width: 100%;
    img { width: 100%; }
  }
  .banner-title-outer-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & > .container-fluid { flex: 1; }

    @media (min-width: @screen-md-min) {
      min-height: var(--banner-min-height);
    }
    @media (max-width: @screen-xs-max) {
      .container {
        width: 100%;
      }
    }
    .banner-component {
      padding-top: 20px;
      padding-bottom: 20px;
      .channel-filter {
        border: 1px solid @white;
        background: rgba(0, 0, 0, 0.2)!important;
        color: @white;
        &:focus {
          outline: none;
        }
      }
    }
    .banner-component--title h1 {
      color: @white;
      font-weight: 700;
      text-shadow: 0 0 6px rgba(0,0,0,0.75);
      font-size: 18px;
      @media (min-width: @screen-sm-min) {
        font-size: 40px;
      }
    }
    @media (max-width: @screen-sm-max) {
      .banner-component-title {
        padding-bottom: 0;
      }
    }
  }
  .banner-title-outer-container--no_banner_image {
    background-color: #000;
    position: static;
  }
  .release-banner-container {
    padding-left: 0px;
    padding-top: 20px;
    max-width: 1025px;
    h1.release-banner-title {
      margin: 0;
      color: var(--market-main);
      text-transform: uppercase;
      font-weight: 600;
      @media (max-width: @screen-xs-max) {
        font-size: 26px;
      }
    }
    .subtitle {
      color: #b3b3b3;
      font-style: italic;
      font-size: 22px;
      font-weight: 400;
      padding-top: 10px;
      line-height: 1.2;
      @media (max-width: @screen-xs-max) {
        font-size: 18px;
      }
    }
  }
  .banner-component--search-entire-site {
    margin-top: 45px;
    padding-left: 0;
    button.search-entire-site {
      float: right!important;
      background-color: transparent;
      border: 1px solid @white;
      padding: 5px 10px;
      color: @white;
      font-size: 14px;
      text-transform: uppercase;
      &:hover, &:focus {
        background-color: @white;
        color: @black;
      }
    }
    @media (max-width: @screen-sm-max) {
      margin-top: 0;
      padding-bottom: 15px;
    }
  }
}
article#release {
  .subtitle {
    font-size: 18px;
    font-weight: 100;
    padding: 0px 0px 15px;
  }
  .release-list-actions {
    .site-btn {
      color: @black;
      margin-right: 5px;
      transition: ease-in-out .25s;
      &:focus, &:hover {
        color: var(--market-main);
      }
      i {
        position: relative;
        top: 1px;
      }
    }
  }
  .published-at { font-size: 1.8rem; }
  .back-to-top-container {
    text-align: center;
  }
  div.content-back-to-top-footer {
    .footer-container {
      display: flex;
      justify-content: center;
      .back-to-top {
        margin-top: 20px;
      }
    }
  }
}
.tab-content {
  .release-item {
    .release-information {
      padding-left: 0;
    }
  }
  .release-item.release-item--with-thumbnail {
    .release-information {
      padding-left: 15px;
    }
  }
}
.release-item {
  .release-information {
    .make-xs-column(12);
  }
}
.release-item--with-thumbnail {
  .release-photo {
    .make-sm-column(3);
    @media (max-width: @screen-xs-max) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .release-information {
    .make-sm-column(9);
    @media (max-width: @screen-xs-max) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.homepage {
  .release-item {
    .release-information {
      .make-xs-column(12);
    }
  }
  .release-item--with-thumbnail {
    .release-photo {
      .make-sm-column(3);
    }
    .release-information {
      .make-sm-column(9);
    }
  }
}
.latest-release-item {
  @media (min-width: @screen-sm-min) {
    min-height: 420px;
  }
  @media (max-width: @screen-sm-min) {
    margin-bottom: 20px;
  }
  .release-photo {
    a {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: block;
      height: 300px;
    }
  }
}
.thumb-attachment-widget {
  border-bottom: 1px solid @line-gray;
  margin-bottom: 20px;
  padding-bottom: 10px;
  .sidebar-header {
    h3 {
      font-size: 20px;
      font-weight: 100;
      margin-bottom: 20px;
      margin-top: 2px;
      text-transform: uppercase;
      a {
        color: @black;
        transition: ease-in-out .25s;
        &:focus, &:hover {
          color: @market-hover;
        }
      }
    }
  }
  .document {
    .filename {
      .dont-break-out();
      color: #595959;
      font-weight: 100;
      transition: ease-in-out .25s;
      &:focus, &:hover {
        color: #b2b2b2;
      }
    }
  }
}
#related-photos, #related-videos {
  header h2 {
    font-weight: normal;
    text-transform: uppercase;
  }
}
.fa-colorized {
  &.fa-file-excel-o { color: #227446; }
  &.fa-file-word-o { color: #2A579A; }
  &.fa-file-powerpoint-o { color: #B84729; }
  &.fa-file-pdf-o { color: #FF0001; }
}
#related-news, #stories, #social {
  .release-item {
    .release-list-actions {
      padding: 0 15px;
      .site-btn {
        margin-right: 5px;
        &:hover, &:focus {
          color: @black;
        }
      }
    }
  }
}
#related-news-widget {
  .related-release-item {
    margin-bottom: 15px;
    .content-title {
      margin-bottom: 5px;
      margin-top: 0;
    }
    .published-at {
      font-weight: 100;
    }
  }
}
#links-widget {
  .ion-link {
    color: @black;
    font-size: 24px;
  }
  .document {
    font-size: 14px;
    margin-left: 5px;
  }
}
#mobile-presskit-dropdown-container {
  .mobile-presskit-dropdown {
    align-content: stretch;
    align-items: stretch;
    display: flex;
    .presskit-type {
      border-color: @black;
      flex: 1;
      &:hover, &:focus {
        background-color: @black;
        color: @white;
      }
    }
    .dropdown-toggle {
      border-color: @black;
    }
  }
  .mobile-presskit-dropdown.open {
    .dropdown-toggle {
      background-color: @black;
      color: @white;
      &:hover, &:focus {
        background-color: @black;
        color: @white;
      }
    }
    .presskit-tabs-ul-mobile {
      border-color: @black;
      border-radius: 0;
      padding-bottom: 0;
      padding-top: 0;
      top: 31px;
      li.active a {
        background-color: @black;
      }
    }
  }
}
p.quote {
  padding: 2em 4em;
  background: #e6e6e6;
  margin: 2em 4em 2.5em;
  @media (max-width: @screen-xs-max) {
    padding: 1em;
    background: #e6e6e6;
    margin: 2em;
  }
}


.bullet-list {
  background-color: #ebebeb;
  padding: 15px 15px 15px 43px;
  border-radius: 4px;
}

/* ====================================================================== */

/* ==== RELEASE SHOW ==================================================== */
/* ====================================================================== */
.release-body {
  font-size: 16px;
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
  .header-banner-container {
    background-color: transparent;
  }
}
.release-body__title {
  color: var(--market-main);
  font-size: 22px;
  font-weight: 900;
  margin-bottom: 0;
  @media (min-width: @screen-sm-min) {
    font-size: 40px;
  }
}
.release-body__subtitle {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 0;
  @media (min-width: @screen-sm-min) {
    font-size: 22px;
  }
}

.release-body__share-add-download-buttons {
  height: 50px;
  line-height: 50px;
  @media (min-width: @screen-sm-min) {
    height: 120px;
    line-height: 120px;
  }
}

.release-body__dateline, .release-body__published-at {
  font-size: 12px;
  font-weight: 700;
  @media (min-width: @screen-sm-min) {
    font-size: 18px;
  }
}
#release-main {
  .well {
    padding: 15px 15px 15px 30px;
    ul {
      padding: 0;
      margin: 0;
    }
  }
  a {
   text-decoration: underline;
    &:hover, &:focus {
      color: var(--market-hover);
    }
  }
}
.release-quote__pull-left, .release-quote__pull-right {
  background-color: var(--market-main);
  color: @white;
  margin-bottom: 10px;
  padding: 20px;
  width: 100%;
  @media (min-width: @screen-md-min) {
    width: 350px;
  }
  p {
    font-family: Adamina;
    font-size: 24px;
    margin: 0;
  }
  h3 {
    color: @white;
    font-size: 20px;
    font-weight: 700;
    margin: 20px 0 10px;
    text-transform: uppercase;
  }
  h4 {
    color: @white;
    font-size: 16px;
    font-weight: 100;
    margin: 0;
    text-transform: uppercase;
  }
}
.release-quote__pull-left, .release-image__pull-left {
  float: none;
  margin-right: 0;
  @media (min-width: @screen-md-min) {
    float: left;
    margin-right: 20px;
  }
}
.release-quote__pull-right, .release-image__pull-right {
  float: none;
  margin-left: 0;
  @media (min-width: @screen-md-min) {
    float: right;
    margin-left: 20px;
  }
}
.release-image__pull-left, .release-image__pull-right {
  margin-bottom: 10px;
  width: 100%;
  @media (min-width: @screen-md-min) {
    width: 400px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.release__links-header {
  color: var(--market-main);
  font-weight: 900;
  text-transform: uppercase;
  margin: 1em 0;
}
.release__links-list {
  list-style: none;
  padding: 0;
}
.release__links-item {
  margin-bottom: 5px;
  i {
    padding-right: 5px;
  }
}
/* ====================================================================== */

/* ==== RELATED RELEASES ================================================ */
/* ====================================================================== */
#related-releases {
  margin-left: -15px;
  margin-right: -15px;
  @media(min-width: @screen-sm-min) {
    margin-left: 0;
    margin-right: 0;
  }
  .homepage-section-container {
    padding: 10px 0;
    @media(min-width: @screen-sm-min) {
      padding: 20px;
    }
    .homepage-section-header {
      h2 {
        margin-bottom: 10px;
        @media(min-width: @screen-sm-min) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .release-container {
    .release-grid-mixin(3, 3);
    .release-item {
      border: none;
      @media(min-width: @screen-sm-min) {
        border-left: solid 10px @light-gray;
        border-right: solid 10px @light-gray;
      }
    }
  }
}
/* ====================================================================== */

/* ==== PHOTOS ========================================================== */
/* ====================================================================== */
.embargo-overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.65);
  bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  font-weight: normal;
  justify-content: center;
  left: 0;
  right: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  top: 0;
  z-index: 20;

  .pub-date {
    color: unset!important;
    padding-left: 0.1rem;
    text-transform: unset!important;
  }
}

.embargo-overlay__button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px 10px 10px;
  background: transparent;
  border: 0;
  font-size: 19px;
}

.embargo-overlay__message {
  background-color: #CC0000;
  padding: 10px 20px;
}

.grid {
  margin-bottom: 40px;
  /* reveal grid after images loaded */
  &.are-images-unloaded {
    opacity: 0;
  }

  .grid__item, .grid__col-sizer {
    width: 49.5%;
    @media (min-width: @screen-sm-min) {
      width: 32.5%;
    }
    @media (min-width: @screen-md-min) {
      width: 24.25%;
    }
  }

  .grid__gutter-sizer {
    width: 1%;
    @media(min-width: @screen-sm-min) {
      width: 1.25%;
    }
    @media (min-width: @screen-md-min) {
      width: 1%;
    }
  }

  .grid__item {
    background-color: @white;
    margin-bottom: 1%;
    transition: box-shadow 80ms;
    @media(min-width: @screen-sm-min) {
      margin-bottom: 1.25%;
    }
    @media (min-width: @screen-md-min) {
      margin-bottom: 1%;
    }
    img {
      display: block;
      max-width: 100%;
    }

    &:hover {
      box-shadow: 0 0 6px rgba(0,0,0,0.5);
    }

    &.embargoed {
      .content-information {
        .grid-item__actions {
          display: none;
        }
      }
    }

    &__overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: rgba(255,255,255,0.95);
      width: 100%;

      &.collapsing {
        transition: none;
        display: none;
      }
    }

    .content-information {
      padding: 10px 10px 5px 10px;
      .content-title {

        margin: 0 0 10px;
        a {
          color: @black;
          font-size: 12px;

          &:hover, &:focus {
            color: var(--market-main);
          }

          @media(min-width: @screen-sm-min) {
            font-size: 16px;
          }
        }
      }

      a {
        color: #b3b3b3;
        font-size: 20px;
        margin-right: 5px;
        transition: ease-in-out .25s;
        &:focus, &:hover {
          color: var(--market-main);
        }
        i {
          font-size: 20px;
        }
      }
    }
  }

  .content-back-to-top-footer > div {
    bottom: -40px;
    position: absolute;
    padding-left: 15px;
    padding-right: 15px;
    @media (min-width: @screen-sm-min) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .social-share-btn {
    border: none;
    padding: 0;
    vertical-align: baseline;
    &:hover {
      background-color: transparent!important;
    }
  }
}

.download-overlay {

  padding-top: 30px;

  // Bootstrap 3 Collapse
  &.collapse.in { display: flex; }

  flex-direction: column;
  text-align: center;

  &__header {
    color: var(--market-main);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 5px;
  }

  &__list {
    flex: 1;
    list-style-type: none;
    margin: 0;
    padding: 0;

    font-size: 16px;
    line-height: 30px;
  }

  &__cancel {
    height: 30px;
    color: black;
    text-transform: uppercase;
  }

}

.photo-embargoed {
  .embargoed {
    background: var(--market-main);
    color: white !important;
    margin: 15px 0 0;
    padding: 10px 15px;
    text-transform: uppercase;
    &:before {
      content: "\f05e";
      display: inline-block;
      font-family: fontawesome;
      padding-right: 5px;
    }
  }
  .modal-footer {
    display: none;
  }
}
.latest-photos {
  .item {
    overflow: hidden;
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      min-height: 320px;
    }
    a.homepage-photo {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: block;
      height: 250px;
      @media (min-width: @screen-md-min) {
        height: 120px;
      }
    }
  }
}

/* ====================================================================== */

/* ==== VIDEOS ========================================================== */
/* ====================================================================== */

.play-button-overlay {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  i {
    color: rgba(255,255,255,0.85);
    font-size: 40px;
    transition: ease-in-out .25s;
  }

  &:hover {
    i {
      color: var(--market-main);
    }
  }
}

@media (max-width: @screen-xs-max) {
  .video-item:last-child {
    .content-information {
      border-bottom: none;
    }
  }
}

.video-item, .photo-item {
  &.embargoed {
    .content-information {
      .content-title {
        a {
          color: var(--market-main)!important;
          &:before {
            font-family: fontawesome;
            content: "\f05e";
            padding-right: 5px;
          }
        }
      }
      .grid-item__actions {
        display: none;
      }
    }
  }
}
.video-embargoed {
  .embargoed {
    background: var(--market-main);
    color: white !important;
    margin: 15px 0 0;
    padding: 10px 15px;
    text-transform: uppercase;
    &:before {
      content: "\f05e";
      display: inline-block;
      font-family: fontawesome;
      padding-right: 5px;
    }
  }
  .modal-footer {
    display: none;
  }
}
.latest-videos {
  .item {
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      min-height: 320px;
    }
    .preview-modal {
      display: block;
      position: relative;
      .play-toggle-overlay {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        bottom: 0;
        top: 0;
        position: absolute;
        width: 100%;
        i.fa-play-circle-o {
          font-size: 73px;
          color: #fff;
          opacity: 0.85;
          transition: ease-in-out .25s;
        }
        &:focus, &:hover {
          i.fa-play-circle-o {
            opacity: 1;
          }
        }
      }
    }
  }
}

/* ====================================================================== */

/* ==== BASKET ========================================================== */
/* ====================================================================== */
#basket-tab-content {
  .tab-pane {
    background-color: @light-gray;
    padding: 0 30px 30px;
    margin-bottom: 30px;
  }
}
#share-basket-btn, #clear-basket-btn {
  background: rgba(0,0,0,0.5);
  border: none;
  color: @white;
  float: right;
  font-size: 14px;
  padding: 5px 10px;
  text-transform: uppercase;
  &:hover {
    color: var(--market-main);
  }
  i {
    font-size: 16px;
  }
}
#clear-basket-btn {
  margin-left: 10px;
  i { padding-top: 3px; }
}
.basket-action-hidden { display: none!important; }
.basket-action-visible { display: inline-block!important; }
#basket-tab-content {
  .custom-actions-nav {
    margin-top: -80px;
    text-align: right;
    .site-btn>i {
      font-size: 16px;
    }
    .basket-download {
      margin-right: 15px;
    }
  }
}
.custom-basket-actions {
  margin: 15px;
  @media (min-width: @screen-sm-min) {
    margin: 0 0 30px;
  }
  a {
    color: @line-gray;
    margin-right: 10px;
    text-transform: uppercase;
    &:hover {
      color: @black;
    }
  }
}
.empty-basket-block--visible {
  p {
    font-size: 18px;
    margin: 30px 0 10px;
  }
}
header#basket {
  @media (max-width: @screen-xs-max) {
    h1 {
      font-size: 34px;
    }
  }
}
div.basket-actions {
  @media (min-width: @screen-xs-max) {
    margin-top: 55px;
  }
  @media (max-width: @screen-sm-max) {
    padding-bottom: 15px;
    a {
      float: left;
      padding-bottom: 15px;
      font-size: 12px;
    }
  }
}

/* ====================================================================== */

/* ==== FORMS =========================================================== */
/* ====================================================================== */
.form-control {
  border-color: @line-gray;
  border-radius: 0;
}
.form-grid__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding: 0 15px;
  @media (min-width: @screen-sm-min) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.form-panel.form-panel--grid {
  margin: 0;
  background: #EBEBEB;
  border: 1px solid #ddd;
  box-shadow: 0 1px 1px rgba(0,0,0,.05);
  .panel-default {
    margin: 0;
    box-shadow: none;
    .panel-body {
      padding: 15px;
      border: none;
    }
  }
}
.form-panel {
  margin-top: 20px;
  .panel-default {
    background-color: @light-gray;
    border: none;
    border-radius: 0;
    .panel-body {
      padding: 30px;
      .site-page-header {
        margin: 0;
        text-align: center;
        h2 {
          color: var(--market-main);
          font-weight: 700;
          margin: 0 0 30px;
        }
      }
    }
    .form-actions-container {
      display: flex;
      justify-content: space-between;
      .checkbox {
        padding: 0;
      }
    }
    .site-form-btn {
      background-color: transparent;
      border: 0;
      color: @black;
      font-size: 18px;
      text-transform: uppercase;
      &:focus, &:hover {
        color: var(--market-main);
      }
      i {
        color: var(--market-main);
      }
    }
  }
  .form-group {
    .control-label {
      padding-right: 0;
      padding-top: 16px;
      text-align: left;
    }
    .form-control {
      border: none;
      height: 50px;
    }
  }
}
/* Form Validation Errors */
.form-global-errors {
  margin-bottom: 15px;
}
ul.form-field-error-messages {
  margin: 0;
  padding: 5px 10px;

  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: var(--market-main);
  }
}
ul.form-global-error-messages {
  padding-top: 10px;

  li {
    list-style-type: square;
    color: @text-gray;
  }
}
.password-instructions {
  border-radius: 0;
  color: #999;
  padding: 5px 8px;
  position: relative;
  top: 20px;
}
#subForm {
  .form-control {
    &:focus {
      border-color: @black;
      box-shadow: 0 1px 1px rgba(0, 0, 0, .25);
    }
  }
}
/* ====================================================================== */

/* ==== PAGE ============================================================ */
/* ====================================================================== */
.page {
  header {
    border: none;
    padding: 0;
    h1 {
      color: var(--market-main);
      font-size: 26px;
      margin: 30px 0 0;
      padding: 0;
      font-weight: bold;
      @media (min-width: @screen-sm-min) {
        font-size: 32px;
      }
    }
    .subtitle {
      color: var(--market-main);
      font-size: 22px;
      font-weight: normal;
    }
  }
  h1 {
    text-align: center;
    font-weight: 100;
    padding: 1em 0;
  }
  p {
    line-height: 1.15em;
    font-size: 15px;
  }
  h2, h3, h4, h5 {
    font-weight: normal;
    text-transform: uppercase;
  }
  h2 {
    font-size: 28px;
    margin-bottom: 30px;
  }
  h3 {
    margin-top: 1em;
    margin-bottom: 0.6em;
    color: @black;
    border: none;
    text-transform: none;
    font-weight: 400;
  }
  h4 {
    color: #cc092f;
    font-size: 22px;
    margin: 1em 0px;
  }
  .panel-footer {
    border: 1px solid #ddd;
    line-height: 1.5;
  }
  .panel-body {
    border: 1px solid #ddd;
    border-bottom: none 0px;
  }
}
#subscribe {
  header h1 {
    display: none;
  }
}
/* ====================================================================== */

/* ==== PRESSKIT NAV ==================================================== */
/* ====================================================================== */
.presskit-body {
  margin-top: 45px;
  @media (min-width: @screen-sm-min) {
    margin-top: 0;
  }
}

.presskit-nav {
  width: 100%;
  top: 0;
  position: sticky;
  z-index: 3;

  @media (min-width: @screen-sm-min) {
    position: absolute;
    left: -300px;

    height: 100%;
    width: auto;
    z-index: 1;
    transition: left 250ms;

    &.is-active {
      left: 0;
    }
  }
}

.presskit-nav.is-active {
  display: block;
}
.presskit-nav__panel {
  background-color: var(--market-main);
  color: var(--mobile-nav-level3-bg);
  display: inline-block;
  padding: 5px 15px;
  width: 100%;
  cursor: pointer;

  @media (min-width: @screen-sm-min) {
    margin-top: 330px;
    text-align: center;
    top: 30px;
    padding: 20px 20px 10px;
    position: sticky;
  }
}
.presskit-nav__icon {
  font-size: 25px;
  margin-bottom: 10px;
}
.presskit-nav__title {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0;
  @media (min-width: @screen-sm-min) {
    font-size: 16px;
    margin-bottom: 5px;
  }
  i {
    float: right;
    line-height: 1.45;
  }
}
.is-expanded .presskit-nav__title {
  margin-bottom: 0;
}
.presskit-nav__list {
  border-top: solid 1px var(--mobile-nav-level2-fg);
  color: var(--mobile-nav-level2-fg);
  font-size: 14px;
  list-style-type: none;
  margin: 9px 0 0 0;
  padding: 0;
  display: none;
  @media (min-width: @screen-sm-min) {
    font-size: 16px;
  }
}
.is-expanded .presskit-nav__list {
  display: block;
}
.presskit-nav__list__item {
  line-height: 36px;
  text-transform: uppercase;
  &:first-child {
    margin-top: 3px;
  }
}
.presskit-nav__link {
  display: block;
  color: var(--mobile-nav-level2-fg);
  &:hover {
    color: var(--mobile-nav-level2-fg);
  }
  white-space: nowrap;
}
/* ====================================================================== */

/* ==== STORY SHOW ====================================================== */
/* ====================================================================== */
.story-header {
  background-size: cover;
  background-position: center;
  padding: 30px 15px;
  @media (min-width: @screen-sm-min) {
    padding: 50px 15px;
  }
  @media (min-width: @screen-md-min) {
    padding: 100px 0;
  }
  .story-header__container {
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0,0,0,0.65);
    padding: 26px 20px 30px 20px;
    .story-header__title {
      color: @white;
      font-size: 24px;
      font-weight: 700;
      margin: 0;
      @media (min-width: @screen-sm-min) {
        font-size: 36px;
      }
      @media (min-width: @screen-md-min) {
        font-size: 45px;
      }
    }
    .release-story__subtitle {
      color: @white;
      font-size: 20px;
      font-weight: 300;
      margin: 15px 0 0;
      @media (min-width: @screen-sm-min) {
        font-size: 32px;
      }
      @media (min-width: @screen-md-min) {
        font-size: 40px;
      }
    }
  }
}
.story-actions {
  background-color: var(--market-main);
  height: 55px;
  margin-bottom: 30px;
  padding: 0 15px;
  @media (min-width: @screen-sm-min) {
    height: 80px;
  }
  @media (min-width: @screen-md-min) {
    padding: 0;
  }
  .story-actions__container {
    display: flex;
    justify-content: space-between;
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 0;
    @media (min-width: @screen-sm-min) {
      padding: 25px 0;
    }
    .story-actions__published-at {
      color: @white;
      font-size: 14px;
      @media (min-width: @screen-sm-min) {
        font-size: 20px;
      }
    }
    .social-media-container .social-media a.btn {
      color: @white;
    }
    .social-media-container .social-media a.btn {
      font-size: 18px;
      @media (min-width: @screen-sm-min) {
        font-size: 22px;
      }
    }
  }
}
/* ====================================================================== */

/* ==== DATERANGE ======================================================= */
/* ====================================================================== */
.daterangepicker {
  border: none!important;
  border-radius: 0!important;
  margin: 0!important;
  &:before {
    display: none!important;
  }
  .calendar {
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      max-width: 230px!important;
    }
  }
  .ranges {
    li {
      border-radius: 0;
      color: @black;
      &:hover {
        background: var(--market-main);
        border: 1px solid var(--market-main);
        color: @white;
      }
    }
    li.active {
      background: var(--market-main);
      border: 1px solid var(--market-main);
    }
  }
  .range_inputs .btn-sm {
    border-radius: 0;
  }
  td {
    border-radius: 0!important;
  }
  td.active, td.active:hover {
    background-color: var(--market-main)!important;
  }
  .input-mini {
    border-radius: 0!important;
  }
  .input-mini.active {
    border: 1px solid @black!important;
  }
  .input-mini.active:focus {
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px!important;
  }
  td.off.start-date, td.off.end-date {
    color: @white!important;
    opacity: .5;
  }
}
/* ====================================================================== */

/* ============ HEADERS ============ */
h1 {
  color: @black;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

.content-title, .content-title a {
  transition: color ease-in-out .25s;
  @media (max-width: @screen-xs-max) {
    font-size: 16px;
  }
  &:hover, &:focus {
    color: @market-hover;
  }
}

.content.homepage {
  background-color: @white;
  border-bottom: 1px solid @line-gray;
  margin-top: 0;
  padding-bottom: 35px;
  .site-page-header {
    border-bottom: none;
    margin: 35px 0;
    @media (max-width: @screen-xs-max) {
      margin: 20px 0;
    }
    h2 {
      display: inline-block;
      margin: 0;
      @media (max-width: @screen-xs-max) {
        margin-top: 5px;
      }
      a {
        color: @black;
        transition: ease-in-out .25s;
        &:focus, &:hover {
          color: @market-hover;
        }
      }
    }
  }
}

.site-page-header {
  @media (min-width: @screen-sm-max) {
    margin: 20px 0;
  }
  padding-bottom: 0;
  h3 {
    margin-top: 0;
  }
  h3, h3>a {
    color: @black;
    text-transform: uppercase;
    transition: ease-in-out .25s;
    &.hoverable {
      &:focus, &:hover {
        color: @market-hover;
      }
    }
  }
  .view-more-btn {
    float: right;
  }
  a.searchbox-icon {
    background-color: @black;
    color: @white;
    float: right;
    font-size: 17px;
    margin-right: 10px;
    padding: 4px 6px;
    text-transform: uppercase;
    transition: ease-in .25s;
    &:focus, &:hover {
      background-color: var(--market-main);
    }
  }
}
.header {
  margin-bottom: 30px;
  h1 {
    font-weight: normal;
    text-transform: uppercase;
  }
}
.header-title {
  color: @black;
  text-transform: none;
}
.header-search-title {
  text-transform: none;
}

.search-header--search-results-for {
  text-transform: uppercase;
}

.search-header--query {
  text-transform: none;
  font-style: italic;
}

.search-header--channel {
  text-transform: none;
}

.header-search {
  h1 {
    @media (max-width: @screen-xs-max) {
      font-size: 28px;
      span {
        font-size: 26px;
      }
    }
  }
}
.no_results_found {
  font-size: 20px;
}
div.search-again {
  form.searchbox {
    div.input-group {
      margin-top: 30px;
      width: 100%;
      z-index: 999;
      input.searchbox-input {
        min-height: 35px;
        font-size: 18px;
        @media (max-width: @screen-xs-max) { font-size: 18px; }
        margin-top: 1px;
      }
      span#search-addon {
        padding: 0;
        background-color: @black;
        border-top-right-radius: 0!important;
        border-bottom-right-radius: 0!important;
        button {
          border-radius: 0!important;
          background-color: @black;
          color: white;
        }
      }
    }
  }
}

/* ============ BUTTONS ============ */
.fa-releases:before {
  content: "\f15b";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}
.fa-photos:before {
  content: "\f030";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}
.fa-videos:before {
  content: "\f144";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}

/* ============ Sitemap ============ */
.site-sitemap {
  background-color: @line-gray;
  padding-bottom: 20px;
  margin-top: 20px;
  .social-media {
    @media (max-width: @screen-sm-max) {
      margin-bottom: 10px;
    }
    a:hover {
      background-color: #FFFFFF;
    }
    .facebook:hover {
      border-color: #3b5998;
      color: #3b5998;
    }
    .twitter:hover {
      border-color: #000000;
      color: #000000;
    }
    .instagram:hover {
      border-color: #125688;
      color: #125688;
    }
    .googleplus:hover {
      border-color: #dd4b39;
      color: #dd4b39;
    }
    .pinterest:hover {
      border-color: #cb2027;
      color: #cb2027;
    }
  }
  .site-page-header {
    margin-bottom: 10px;
  }
  .input-group {
    .input-group-btn {
      a.btn-default:hover {
        background-color: @white;
      }
    }
  }
}

/* ============ Subscribe ============ */
#campaignMonitorModal {
  .modal-content {
    background: @white;
    color: @black;
    border-radius: 0px;
    -webkit-border-radius: 0px;
  }
  .modal-header, .modal-body, .modal-footer {
    border: none;
  }
  .modal-header {
    text-align: center;
    h5.modal-title {
      color: var(--market-main);
      font-size: 24px;
      margin: auto;
    }
  }
  .modal-body {
    text-align: center;
  }
  input {
    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid @black;
    width: 90%;
    margin-bottom: 1em;
    color: @black;
    &:focus {
      outline: none;
    }
  }
  button.js-cm-submit-button {
    border: 1px solid white;
    background: var(--market-main);
    color: @white;
    text-transform: uppercase;
    padding: 5px 15px;
    font-size: 14px;
    -webkit-transition: all 0.5s;
  }
  button:hover {
    cursor: pointer;
    background: @market-hover;
    -webkit-transition: all 0.5s;
  }
}

/* ============ SHARE MODAL ============ */
.social-media-container {
  display: inline;
  .social-media {
    a.btn, a.btn.dropdown-toggle {
      border: none;
      box-shadow: none;
      color: @line-gray;
      font-size: 22px;
      padding: 0 7px;
      top: -4px;
      transition: ease-in-out .25s;
      background: none;
      &:hover {
        margin-top: -2px;
      }
      @media (min-width: @screen-sm-max) {
        font-size: 21px;
        top: -2px;
      }
    }
    #download-options {
      border-radius: 0;
      left: auto;
      right: 0;
      padding: 5px 15px;
      .download-options__header h4 {
        color: var(--market-main);
        font-size: 14px;
        font-weight: 700;
        margin: 0;
        padding: 5px 0;
        text-transform: uppercase;
      }
      .download-options__title h4 {
        color: @black;
        font-size: 14px;
        font-weight: 700;
        margin: 0;
        padding: 5px 0;
        text-transform: capitalize;
      }
      .download-options__item a {
        background: transparent;
        color: @black;
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        padding: 5px 0;
        &:hover {
          color: var(--market-main);
        }
        i {
          margin: 3px 0 0 5px;
        }
      }
      .download-options__item--with-divider {
        border-bottom: 1px solid @text-gray;
        padding-bottom: 5px;
        margin-bottom: 5px
      }
    }
    @media (max-width: @screen-xs-max) {
      a.btn.facebook {
        padding-left: 0;
      }
    }
    .facebook {
      &:hover, &:focus {
        color: #3b5998;
      }
    }
    .twitter {
      &:hover, &:focus {
        color: #000000;
      }
    }
    .linkedin {
      &:hover, &:focus {
        color: #0077B5;
      }
    }
    .instagram {
      &:hover, &:focus {
        color: #125688;
      }
    }
    .googleplus {
      &:hover, &:focus {
        color: #dd4b39;
      }
    }
    .pinterest {
      &:hover, &:focus {
        color: #cb2027;
      }
    }
  }
}
dd.error {
  color: red;
}

/* ============ HOMEPAGE CTA ============ */
#homepage-cta {
  .homepage-section-border {
    margin-bottom: 20px;
  }
  iframe {
    display: block;
    width: 100%;
  }
  .homepage-photo-cta {
    background-position: center center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    display: block;
    height: 126px;
    overflow: hidden;
    width: 100%;
    @media (max-width: @screen-sm-max) {
      margin-top: 20px;
    }
  }
}


/* Embedded Video Container */
/* Youtube Video Container - Wrap Youtube embeds in .youtube-video-container to make them responsive */
.youtube-video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
  margin-top: 20px;
}
.youtube-video-container iframe,
.youtube-video-container object,
.youtube-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Toast

#toast-container > div {
  opacity: 1!important;
  border: solid 1px #d0e9c6;
  padding: 15px 15px 15px 15px;
}

#toast-container > .toast-success {
  background-image: none!important;
}

.toast-success {
  background-color: #dff0d8!important;
  color: #3c763d!important;
}

.toast-close-button {
  color: #3c763d!important;
}

