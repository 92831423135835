.page-preview-connection-status-indicator {
  position: fixed;
  top: 10px;
  border: solid 1px #0000002e;
  z-index: 999999;
  color: white;
  border-radius: 5px;
  display: flex;
  left: -2px;
  background-color: rgba(131, 23, 23, 0.94);
  opacity: 0.7;
  transition: all 150ms ease-in-out;

  .page-preview-connection-status-indicator__message {
    padding: 5px 10px 6px 10px;
  }

  .page-preview-connection-status-indicator__toggle-button {
    border-left: solid 1px #0000002e;
    padding: 5px 10px 6px 10px;
    cursor: pointer;
  }

  .page-preview-connection-status-indicator__message--active { display: none; }
  .page-preview-connection-status-indicator__message--inactive { display: none; }

  &.is-fully-visible {
    left: 10px;
    opacity: 1.0;

    .page-preview-connection-status-indicator__message--active { display: none; }
    .page-preview-connection-status-indicator__message--inactive { display: block; }

    &.connection-is-active {
      .page-preview-connection-status-indicator__message--active { display: block; }
      .page-preview-connection-status-indicator__message--inactive { display: none; }
    }
  }

  &.connection-is-active {
    background-color: rgba(23, 131, 60, 0.94);

    //.page-preview-connection-status-indicator__message--active { display: block; }
    //.page-preview-connection-status-indicator__message--inactive { display: none; }
  }
}