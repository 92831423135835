svg.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  .play {
    fill: rgba(0, 0, 0, 0);
    stroke: rgba(255,255,255, 1);
    stroke-width: 2;
    stroke-dasharray: 400;
    stroke-dashoffset: 0;
    stroke-linecap: square;
    transition: all .6s ease-out;
  }

  &:hover {
    .play {
      fill: @market-main;
      stroke: @market-main;
      stroke-dashoffset: 800;
      transition: stroke .7s .4s ease-out, stroke-dashoffset .4s ease-out, fill .4s .3s ease-out;
    }
  }
}