.dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

/* =========== GOLDEN RATIO =========== */
.golden-image {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.golden-image--62 {
  padding-top: 62%;
}

// 16:9
.golden-image--56 {
  padding-top: 56.25%;
}
/* ==================================== */