@text-gray: #AAAAAA;
@white: #FFFFFF;
@black: #000000;
@off-black: #1D1D1D;
@dark-gray: #222222;
@light-gray: #EBEBEB;
@line-gray: #7A7A7A;
@market-main: #BB261A;
@market-hover: #215583;

@import '_base.less';

.juicer-feed a:hover {
  color: #b2b2b2;
}
.juicer-feed .j-meta a:hover {
  color: #000000;
}
.j-twitter-poster:hover {
  color: #b2b2b2;
}

:root {
  /* Channel, Release, Basket, Search Results Banner min-height */
  --banner-min-height: 250px;
  --market-main: @market-main;
  --market-hover: #BB261A;
  --mobile-nav-level2-bg: #CDCDCD;
  --mobile-nav-level2-fg: white;
  --mobile-nav-level3-bg: white;
  --mobile-nav-level3-fg: black;
}

body.theme--acura {
  --market-main: @market-main;
}

.fa.fa-twitter::before {
  content: "𝕏"!important;
}

.release-grid-mixin(@sm-to-md: 2, @lg: 4) {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 0;
  flex-direction: row;

  .release-item {
    padding: 0!important;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }

    // * -> sm: 1-wide
    width: 100%;

    a {
      width: 50%;
    }

    // sm <-> md
    @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
      border-left: solid 5px @white;
      border-right: solid 5px @white;
      flex-direction: column;
      margin-bottom: 10px;

      width: calc(100% / @sm-to-md);

      &:nth-last-child(-n+@{sm-to-md}) {
        margin-bottom: 0;
      }
      &:nth-child(@{sm-to-md}n) {
        border-right: none;
      }
      &:nth-child(@{sm-to-md}n+1) {
        border-left: none;
      }

      a {
        width: 100%;
      }
    }

    @media (min-width: @screen-lg-min) {
      border-left: solid 5px @white;
      border-right: solid 5px @white;
      flex-direction: column;
      margin-bottom: 10px;

      width: calc(100% / @lg);
      &:nth-last-child(-n+@{lg}) {
        margin-bottom: 0;
      }
      &:nth-child(@{lg}n) {
        border-right: none;
      }
      &:nth-child(@{lg}n+1) {
        border-left: none;
      }

      a {
        width: 100%;
      }
    }

    summary {
      display: none;
    }

    .release-list-actions-container {
      display: none;
    }

    .row {
      margin: unset!important;
    }

    .col-xs-12 {
      padding: unset!important;
    }

    div.release-information.embargoed {
      .pub-date {
        color: @state-danger-text!important;
        margin-top: 5px;
        text-transform: capitalize!important;
      }
    }

    div.release-information {
      width: 50%;
      padding: 10px;

      @media(min-width: @screen-sm-min) {
        width: 100%;
      }

      .release-information-row {
        display: grid;

        grid-template-columns: 100%;
        grid-template-rows: auto auto;

        grid-template-areas: "pub-date" "title";

        @media(min-width: @screen-sm-min) {
          grid-template-areas: "title" "pub-date";
        }
      }

      .pub-date {
        grid-area: pub-date;
        color: #b3b3b3!important;
        margin-top: 5px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 300;
        @media(min-width: @screen-sm-min) {
          margin-top: inherit;
          margin-top: 5px;
          margin-bottom: 0;
          font-size: 14px;
        }

        @media(min-width: @screen-md-min) {
          color: #b3b3b3!important;
        }
      }

      .content-title-container {
        grid-area: title;
      }
    }

    figure {
      width: 100%;
    }

    .content-title {
      margin: 0;
      a {
        color: @white;
        font-size: 14px;
        font-weight: 300;
        @media(min-width: @screen-sm-min) {
          font-size: 16px;
        }
      }
    }

    // Hide the img we use for list layout
    .release-list__photo {
      display: none;
    }

  }
}
.release-grid .release-container {
  // No matter what, if being asked to display a release-grid, do it.
  .release-grid-mixin(2, 4);
}

/* ==== GLOBAL STYLES =================================================== */
/* ====================================================================== */
html {
  body {
    font-family: 'Lato', serif;
    margin: 0;
    padding-bottom: 0;
    h2 {
      font-weight: 700;
      letter-spacing: 4px;
    }
  }
  body.site--acura {
    padding-top: 50px;
    @media(min-width: @screen-sm-min) {
      padding-top: 100px;
    }
  }
}
.tab-pane.channel-tab {
  background-color: transparent!important;
  margin-top: 10px;
  padding: 0;
  .photos-actions {
    margin-left: -15;
  }
}
#release-tab-content .tab-pane.channel-tab {
  background-color: transparent;
}
.back-to-top-footer.back-to-top-homepage-footer {
  margin: 0 0 50px;
}
/* ====================================================================== */

/* ==== NAVBAR ========================================================== */
/* ====================================================================== */
.navbar.site-navbar {
  background-color: @black;
  border-radius: 0;
  .navbar-container {
    .nav-logo {
      margin: 5px 0;
    }
    h2.navbar-header-title {
      margin: 0 0 10px;
      @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        display: flex;
        flex: 1;
        margin: 0;
      }
      a {
        border-left: 4px solid @market-main;
        color: @white;
        font-size: 23px;
        letter-spacing: 4px;
        padding-left: 10px;
        margin-left: 20px;
        text-transform: uppercase;
        font-weight: normal;
      }
    }
    .nav.navbar-nav {
      li {
        a {
          color: @white;
          font-size: 16px;
          font-weight: 300;
          text-transform: uppercase;
          padding-left: 10px;
          padding-right: 10px;
        }
        span.caret {
          border-top: 6px dashed;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
          color: @market-main;
        }
      }
      li.dropdown.open a {
        background: transparent;
      }
    }
    .acura-custom-navbar-menu {
      display: flex;
      flex: 1;
      justify-content: center;
      a {
        padding: 15px 5px;
      }
      .dropdown-menu {
        border-radius: 0;
        z-index: 10000;
        .dropdown-submenu a {
          color: @black;
          font-weight: 400;
          padding: 5px 10px;
          &:hover {
            color: @market-main;
          }
        }
      }
    }
    .navbar-actions {
      a {
        color: @white;
      }
      .account-dropdown-menu {
        .close-account-dropdown-menu, li a {
          color: @black!important;
          text-transform: capitalize;
          &:hover {
            color: @market-main!important;
          }
        }
      }
      li.choose-lang {
        padding-top: 12px;
        padding-right: 5px;
        a {
          display: inline;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        span {
          color: @white;
        }
      }
    }
    .hamburger {
      background: transparent;
      border: none;
      margin-right: 0;
      padding: 10px 0;
      span {
        background-color: @white;
      }
    }
  }
}
.nav-logo img {
  height: 40px;
}
#side-navbar {
  top: 50px;
  left: -100%;
  padding: 0;
  width: 100%;
  z-index: 1000;
  @media(min-width: @screen-sm-min) {
    top: 100px;
    right: -335px;
    width: 335px;
  }
  ul.custom-navbar-menu {
    li {
      padding: 5px 0 0;
    }
    li.menu-item > a {
      display: block;
      font-size: 16px;
      font-weight: 400;
      padding: 5px 15px;
      text-transform: uppercase;
      @media(min-width: @screen-sm-min) {
        font-size: 18px;
      }
      i {
        font-size: 16px;
        padding-top: 3px;
        @media(min-width: @screen-sm-min) {
          font-size: 20px;
          padding-top: 0;
        }
      }
    }
    li.menu-item > a.dropdown-toggle {
      i {
        color: var(--market-main);
        left: auto;
        right: 15px;
      }
    }
    li.menu-item:first-child {
      padding: 0;
    }
    li.menu-item.mobile-nav-action > a {
      padding-left: 45px;
    }
  }
  div.menu-divider {
    border-bottom: 2px solid @black;
    margin: 0 15px;
  }
}
#side-navbar.active {
  left: 0;
  @media(min-width: @screen-sm-min) {
    left: auto;
    right: 0;
  }
}
.canada--logo__acura {
  width: 143px;
  @media(min-width: @screen-sm-min) {
    width: 170px;
  }
}
/* ====================================================================== */

/* ==== SEARCHBOX ======================================================= */
/* ====================================================================== */
.modal-dialog.search-modal-dialog {
  .modal-content {
    background-color: @black;
    .modal-header {
      .modal-title {
        color: @white;
        font-weight: 300;
        letter-spacing: 1px;
      }
      button.close {
        color: @line-gray;
      }
    }
    form.searchbox {
      .searchbox-input {
        background-color: @dark-gray;
      }
      .go-btn {
        background: transparent;
        border: none;
        color: @white;
        font-size: 20px;
        font-weight: 300;
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        text-transform: uppercase;
        z-index: 2;
        i {
          color: var(--market-main);
          margin-top: 4px;
          padding-left: 5px;
        }
      }
    }
  }
}
.modal-backdrop.in {
  opacity: .65;
}
/* ====================================================================== */

/* ==== FOOTER ========================================================== */
/* ====================================================================== */
.site-footer-container {
  .site-footer-legal {
    background-color: @market-main;
    padding: 10px 15px;
    .site-footer-search {
      display: flex;
      justify-content: center;
      .searchbox {
        width: auto;
        @media(min-width: @screen-sm-min) {
          width: 400px;
          display: flex;
          align-items: center;
        }
        .searchbox-input {
          background: transparent;
          border: none;
          border-bottom: 2px solid white;
          color: @white;
          font-size: 18px;
        }
        .searchbox-input::placeholder {
          color: @white;
        }
        .input-group-btn {
          width: auto;
          button {
            background: transparent;
            border: none;
            border-radius: 0;
            border-bottom: 2px solid white;
            color: @white;
          }
        }
      }
    }
  }

  .onetrust-footer {
    color: white;
    margin-bottom: 15px;

    & a {
      color: white;
      text-decoration: underline;

      &:hover {
        color: var(--market-main);
      }
    }
  }

  .site-footer-links {
    background-color: @black;
    padding: 20px 0;
    height: 100%;

    .site-footer-links-container {
      display: block;
      .site-links {
        display: flex;
        justify-content: space-around;
        a {
          color: @light-gray;
          font-size: 16px;
          &:hover, &:focus {
            color: @white;
          }
        }
      }
      .social-media {
        display: flex;
        justify-content: center;
        margin: 0 0 20px;
        @media(min-width: @screen-sm-min) {
          margin: 30px 0 50px;
        }
        .social-btn {
          color: @white;
          margin: 0 15px;
          &:hover, &:focus {
            color: @market-main;
          }
        }
      }
      .quick-links {
        display: flex;
        justify-content: center;
        margin: 0;
        a {
          color: @light-gray;
          font-size: 12px;
          margin: 0 5px;
          text-transform: none;
          @media(min-width: @screen-sm-min) {
            margin: 0 20px 0 10px;
          }
          &:hover, &:focus {
            color: @white;
          }
        }
      }
    }
  }
}
/* ====================================================================== */

/* ==== CAROUSEL ======================================================== */
/* ====================================================================== */
.homepage-carousel-container {
  #homepage-carousel {
    margin-top: 0;
    .item.active {
      display: block;
      height: auto;
    }
    .item {
      .homepage-carousel-caption {
        background-color: @black;
        padding: 10px;
        text-align: center;
        width: 100%;
        @media(min-width: @screen-md-min) {
          padding: 20px;
        }
        h2 {
          color: @white;
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 1px;
          margin: 0 0 15px;
          @media(min-width: @screen-sm-min) {
            font-size: 26px;
          }
          a {
            color: @white;
          }
        }
        date {
          color: #b3b3b3;
          font-size: 18px;
          font-weight: 300;
        }
      }
    }
    .carousel-control {
      padding-top: 22%;
      opacity: 1;
    }
    .carousel-control-indicator-wrapper {
      background-color: rgba(0,0,0,.5);
      font-size: 30px;
      line-height: 1em;
      padding: 10px 17px;
      transition: 250ms ease-in-out;
      opacity: 1;
      &:hover {
        background-color: rgba(0,0,0,1);
        color: var(--market-main);
      }
      @media(min-width: @screen-sm-min) {
        padding: 15px 26px;
      }
    }
  }
}
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .homepage-carousel-container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    .row {
      margin: 0;
      .container {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
      }
    }
  }
}
/* ====================================================================== */

/* ==== HOMEPAGE ======================================================== */
/* ====================================================================== */
.homepage.container {
  margin-bottom: 25px;
  @media(min-width: @screen-md-min) {
    margin-bottom: 50px;
  }
}
.homepage {
  .homepage-section {
    .homepage-section-header {
      margin-bottom: 25px;
      h2 {
        color: @black;
        font-size: 24px;
        margin-bottom: 10px;
      }
      .homepage-section-header-border {
        border-bottom: 4px solid @market-main;
        display: flex;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        width: 30px;
      }
      .search-btn {
        font-size: 16px;
        position: absolute;
        left: 15px;
        top: 5px;
        text-transform: uppercase;
        i {
          color: @market-main;
        }
      }
      .more-btn {
        font-size: 16px;
        position: absolute;
        right: 15px;
        top: 5px;
        text-transform: uppercase;
        i {
          color: @market-main;
        }
      }
    }
    .homepage-section-container {
      background-color: transparent;
      padding: 0;
    }
    .release-container {
      .release-item {
        background-color: @black;
        .release-item-information {
          date {
            color: @light-gray;
          }
        }
        .content-title a {
          color: @white;
        }
      }
    }
  }
  .homepage-section.release-grid {
    .release-container .release-item {
      a.release-item-container {
        background-color: @black;
      }
      .release-item-information {
        .title {
          color: @white;
        }
      }
    }
  }
}
/* ====================================================================== */

/* ==== LATEST NEWS ===================================================== */
/* ====================================================================== */
.homepage {
  #latest-news {
    .release-container {
      .release-grid-mixin(3, 3);
    }
  }
}
/* ====================================================================== */

/* ==== MOTORSPORTS ===================================================== */
/* ====================================================================== */
.homepage {
  #motorsports {
    .release-container {
      .release-grid-mixin(3, 3);
    }
  }
}
/* ====================================================================== */

/* ==== BRAND =========================================================== */
/* ====================================================================== */
.homepage {
  #brand {
    .release-container {
      .release-grid-mixin(3, 3);
    }
  }
}
/* ====================================================================== */

/* ==== TRENDING ======================================================== */
/* ====================================================================== */
.homepage {
  #trending-news {
    .release-container {
      .release-grid-mixin(3, 3);
    }
  }
}
/* ====================================================================== */

/* ==== HOMEPAGE PHOTOS/VIDEOS ========================================== */
/* ====================================================================== */
.homepage-media-contianer {
  background-color: @light-gray;
  margin-bottom: 50px;
  .homepage {
    margin-bottom: 0;
    #homepage-photo-search {
      padding-right: 0;
      .homepage-section-container {
        padding: 20px 0 10px 0;
        @media(min-width: @screen-sm-min) {
          padding: 30px 5px 30px 0;
        }
      }
    }
    #homepage-video-search {
      padding-left: 0;
      .homepage-section-container {
        padding: 10px 0 20px 0;
        @media(min-width: @screen-sm-min) {
          padding: 30px 0 30px 5px;
        }
      }
    }
    #homepage-photo-search, #homepage-video-search {
      width: 100%;
      @media(min-width: @screen-sm-min) {
        width: 50%;
      }
      figure {
        margin-bottom: 0;
      }
      .media-search-overlay {
        width: 90%;
        @media(min-width: @screen-sm-min) {
          width: 70%;
        }
        h2 {
          font-size: 24px;
          margin-bottom: 10px;
        }
        .homepage-section-header-border {
          border-bottom: 4px solid @market-main;
          display: flex;
          justify-content: space-around;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 30px;
          width: 30px;
        }
        .media-overlay-searchbox {
          margin: 60px 0 0;
          @media(min-width: @screen-md-min) {
            margin: 0 0 30px;
          }
          input {
            height: 60px;
            font-size: 16px;
            @media(min-width: @screen-md-min) {
              height: 60px;
            }
          }
          button {
            border: none;
            border-radius: 0;
            color: @market-main;
            font-size: 40px;
            background-color: rgba(255,255,255,0.85);
            height: 60px;
            padding: 0px 15px;
            @media(min-width: @screen-md-min) {
              height: 60px;
              padding: 0px 20px;
            }
          }
        }
      }
    }
  }
}
/* ====================================================================== */

/* ==== HOMEPAGE QUOTE ================================================= */
/* ====================================================================== */
#homepage-quote {
  .homepage-section-quote {
    text-align: center;
    h2 {
      font-style: italic;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4em;
      margin: 0px 0 50px;
      padding: 25px 50px 0;
      @media(min-width: @screen-md-min) {
        font-size: 22px;
      }
      i {
        color: @market-main;
        font-size: 26px;
        position: absolute;
        left: 15px;
        top: 0;
        @media(min-width: @screen-md-min) {
          font-size: 32px;
        }
      }
    }
    .homepage-section-quote-border {
      border-bottom: 4px solid @light-gray;
      display: flex;
      justify-content: space-around;
      margin-left: auto;
      margin-right: auto;
      width: 90px;
    }
    h3 {
      font-size: 14px;
      letter-spacing: 4px;
      margin: 50px 0 0;
      text-transform: uppercase;
      span {
        font-weight: bold;
      }
    }
  }
}
/* ====================================================================== */

/* ==== EMBARGO CHANNEL DESCRIPTION ===================================== */
/* ====================================================================== */
.embargo-channel-description {
  margin-top: 15px;
  border: none;
  background-color: #454545;
  padding: 15px;
  color: white;
  font-size: 1.4rem;

  @media(min-width: @screen-sm-min) {
    border: 0;
    margin-top: 25px;
    margin-bottom: 5px;
    padding: 15px;
    font-size: 1.8rem;
  }

  @media(min-width: @screen-md-min) {
    border-width: 30px;
    padding: 30px;
    font-size: 2rem;
  }
}
/* ====================================================================== */

/* ==== CONTENT TYPE TAB BAR ============================================ */
/* ====================================================================== */
.content-type-tab-bar-container {
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    width: 100%;
  }
}
#content-type-tab-bar {
  background-color: @black;
  margin: 0 -15px;
  padding: 0;
  @media(min-width: @screen-md-min) {
    background-color: transparent;
    margin: -60px 0 0;
  }
  .site-media-tabs {
    .nav-tabs {
      .content-type {
        margin-bottom: 0;
        a {
          background: @dark-gray;
          border: none;
          border-radius: 0;
          color: @white;
          font-size: 18px;
          margin: 0;
          padding: 10px;
          @media(min-width: @screen-sm-min) {
            padding: 15px 20px;
          }
          @media(min-width: @screen-md-min) {
            background: rgba(255, 255, 255, 0.3);
            margin: 0 10px 0 0;
            text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
          }
          &:hover, &:focus {
            background-color: @market-main;
          }
          i {
            @media(min-width: @screen-sm-min) {
              display: none;
            }
          }
          .content-type-span {
            border-bottom: none!important;
          }
        }
      }
      .content-type.active>a {
        background-color: @market-main;
      }
    }
  }

  .custom-media-actions {
    #filterForm {
      @media(min-width: @screen-sm-min) {
        padding-top: 7px;
      }

      @media(min-width: @screen-md-min) {
        padding-top: 0;
      }
    }
    .custom-btn-group {
      #daterange-mobile {
        display: none!important;
      }
      #daterange-desktop, .dropdown-toggle {
        background: transparent;
        border: none;
        color: @white;
        margin: 0;
        padding: 10px;
        @media(min-width: @screen-md-min) {
          margin: 0 5px;
          padding: 2px 10px;
        }
        @media(max-width: @screen-sm-max) {
          span {
            display: none;
          }
        }

        &.is-active, &.open {
          color: #f5f5f5;
          @media (min-width: @screen-sm-min) {
            background-color: transparent;
          }
        }

        @media (min-width: @screen-sm-min) {
          &:hover {
            color: #f5f5f5;
          }
        }
      }
    }
  }

}
.daterangepicker.show-calendar.opensleft:after {
  right: 22px;
}


#daterange-desktop__dropdown {
  top: 40px;
  left: -119px;

  @media (min-width: @screen-sm-min) {
    top: 45px;
    left: -119px;
  }

  @media (min-width: @screen-md-min) {
    top: 60px;
    left: -23px;
  }

  &:before {
    left: ~"calc(87% - 10px)";

    @media (min-width: @screen-sm-min) {
      left: ~"calc(87% - 10px)";
    }

    @media (min-width: @screen-md-min) {
      left: ~"calc(50% - 10px)";
    }
  }
}

#content-type-tab-bar .custom-media-actions .custom-btn-group.open ul#sort-options {
  top: 48px;

  @media (min-width: @screen-sm-min) {
    top: 48px;
  }

  &:before {
    margin-top: -15px;
    left: ~"calc(86% - 10px)";

    @media (min-width: @screen-sm-min) {
      left: ~"calc(66% - 10px)";
    }
  }
}

/* ====================================================================== */

/* ==== CHANNELS ======================================================== */
/* ====================================================================== */
.header-banner {
  .banner-title-outer-container {
    .banner-component--title h1 {
      color: @white;
      font-size: 32px;
      font-weight: 300;
      margin: 0;
      @media(min-width: @screen-sm-min) {
        font-size: 50px;
        margin: 0 0 60px;
      }
    }
  }
}
.search-header--search-results-for {
  color: @white;
  display: block;
  font-size: 26px;
  font-weight: 400;
  text-transform: uppercase;
  @media(min-width: @screen-sm-min) {
    font-size: 36px;
  }
}
.search-header--query {
  display: block;
  font-size: 36px;
  @media(min-width: @screen-sm-min) {
    font-size: 50px;
  }
}
// Embargoed Channel Show Page
div.embargoed-channel {
  .header-banner {
    .banner-title-outer-container {
      padding-bottom: 0;
      @media(min-width: @screen-md-min) {
        padding-bottom: 60px;
      }
      .banner-component--title {
        h1 {
          margin: 0 0 20px;
        }
      }
    }
  }
}
/* ====================================================================== */

/* ==== RELEASE SHOW ==================================================== */
/* ====================================================================== */
#release-tab-content {
  .tab-pane.channel-tab {
    padding: 30px 0;
    @media(min-width: @screen-sm-min) {
      padding: 30px 0px;
    }
    .grid {
      .grid__item, .grid__col-sizer {
        width: 49.5%;
        @media (min-width: @screen-sm-min) {
          width: 32.5%;
        }
        @media (min-width: @screen-md-min) {
          width: 24.25%;
        }
      }
      .grid__gutter-sizer {
        width: 1%;
        @media(min-width: @screen-sm-min) {
          width: 1.25%;
        }
        @media (min-width: @screen-md-min) {
          width: 1%;
        }
      }
    }
  }
}
.release-body {
  max-width: 1025px;
  margin-left: auto;
  margin-right: auto;
  .release-body__title {
    color: @black;
    text-align: center;
  }
  .release-body__subtitle {
    font-weight: 400;
    margin-top: 15px;
    text-align: center;
  }
  .release-body__border {
    border-bottom: 4px solid @market-main;
    display: flex;
    justify-content: space-around;
    margin: 25px auto;
    width: 90px;
  }
  #content-type-tab-bar {
    margin: 0 0 20px;
    .site-media-tabs {
      .nav-tabs {
        .content-type {
          a {
            background: transparent;
            color: @black;
            &:hover, &:focus {
              color: @market-main;
            }
            .content-type-span {
              font-weight: 300;
            }
          }
        }
        .content-type.active > a {
          background: @market-main;
          color: @white;
        }
      }
    }
  }
  .release-body__share-add-download-buttons {
    @media (min-width: @screen-sm-min) {
      height: 59px;
      line-height: 59px;
    }
  }
}
/* ====================================================================== */

/* ==== RELATED RELEASES ================================================ */
/* ====================================================================== */
#related-releases {
  margin-left: -15px;
  margin-right: -15px;
  @media(min-width: @screen-sm-min) {
    border-top: 1px solid @black;
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
    padding-top: 30px;
  }
  .homepage-section-container {
    padding: 10px 0;
    @media(min-width: @screen-sm-min) {
      padding: 20px;
    }
    .homepage-section-header {
      h2 {
        margin-bottom: 10px;
        @media(min-width: @screen-sm-min) {
          margin-bottom: 20px;
        }
      }
    }
  }
  .release-container {
    .release-grid-mixin(3, 3);
    .release-item {
      border: none;
      @media(min-width: @screen-sm-min) {
        background: transparent;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
      }
      div.release-information {
        @media (min-width: @screen-sm-min) {
          padding: 0 0 10px;
        }
        .release-information-row {
          grid-template-areas: "title" "pub-date";
          @media (min-width: @screen-sm-min) {
            grid-template-areas: "pub-date" "title";
          }
        }
        .content-title {
          a {
            @media(min-width: @screen-sm-min) {
              color: @black;
            }
          }
        }
      }
    }
  }
}
/* ====================================================================== */

/* ==== RELEASES ======================================================== */
/* ====================================================================== */
.tab-content {
  .release-item {
    background-color: @black;
    padding: 10px 0;
    @media (min-width: @screen-sm-min) {
      background-color: @white;
    }
    .release-information {
      .content-title {
        a {
          color: @white;
          font-size: 14px;
          font-weight: 400;
          @media (min-width: @screen-sm-min) {
            color: @black;
            font-size: 18px;
          }
          @media (min-width: @screen-md-min) {
            font-size: 24px;
          }
          &:hover {
            color: @market-main;
          }
        }
      }
    }
  }
}
/* ====================================================================== */

/* ==== PHOTOS ========================================================== */
/* ====================================================================== */
.grid {
  .grid__item, .grid__col-sizer {
    width: 49.5%;
    @media (min-width: @screen-sm-min) {
      width: 32.5%;
    }
    @media (min-width: @screen-md-min) {
      width: 24.25%;
    }
  }
  .grid__gutter-sizer {
    width: 1%;
    @media(min-width: @screen-sm-min) {
      width: 1.25%;
    }
    @media (min-width: @screen-md-min) {
      width: 1%;
    }
  }
}
/* ====================================================================== */

/* ==== BASKET ========================================================== */
/* ====================================================================== */
#basket-tab-content {
  .tab-pane {
    background: transparent;
    padding: 0;
  }
}
#share-basket-btn, #clear-basket-btn {
  background: rgba(0,0,0,0.5);
  border: none;
  color: @white;
  float: right;
  font-size: 14px;
  padding: 5px 10px;
  text-transform: uppercase;
  &:hover {
    color: var(--market-main);
  }
  i {
    font-size: 16px;
  }
}
#clear-basket-btn {
  margin-left: 10px;
  i { padding-top: 3px; }
}
.basket-action-hidden { display: none!important; }
.basket-action-visible { display: inline-block!important; }


.custom-actions-nav {
  margin: 0 10px 10px;
  @media (min-width: @screen-sm-min) {
    margin: 0 0 10px;
  }
  .site-btn {
    text-transform: uppercase;
  }
  .basket-download {
    margin-right: 15px;
  }
}


.empty-basket-block--visible {
  p {
    font-size: 18px;
    margin: 30px 0 10px;
  }
}
header#basket {
  @media (max-width: @screen-xs-max) {
    h1 {
      font-size: 34px;
    }
  }
}
div.basket-actions {
  margin-right: 15px;
  padding-bottom: 0;
  @media (min-width: @screen-sm-min) {
    //margin: 8px 15px 8px 0;
    margin: 55px 0;
  }
  @media (min-width: @screen-md-min) {
    margin: 36px 0 0 0;
  }
  #clear-basket-btn {
    margin-left: 15px;
  }
  #share-basket-btn, #clear-basket-btn {
    float: right;
    //padding: 10px 0;
  }
}
#basket-body {
  .header-banner .banner-title-outer-container .banner-component {
    padding-top: 20px;
    padding-bottom: 20px;

    @media (min-width: @screen-sm-min) {
      padding-top: 55px;
      padding-bottom: 0;
    }

    @media (min-width: @screen-md-min) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

/* ====================================================================== */

/* ==== FORMS =========================================================== */
/* ====================================================================== */
.form-panel {
  .panel-default {
    background-color: @white;
    box-shadow: none;
    .panel-body {
      .site-page-header {
        margin-bottom: 20px;
        text-align: center;
        h2 {
          color: @black;
          font-size: 24px;
          margin-bottom: 5px;
        }
        .header-border {
          border-bottom: 4px solid @market-main;
          display: flex;
          justify-content: space-around;
          margin-left: auto;
          margin-right: auto;
          width: 30px;
        }
      }
      .acura-form-input {
        background-color: @light-gray;
      }
      .site-form-btn {
        font-weight: 300;
        padding: 0;
      }
    }
  }
}
/* ====================================================================== */

/* ==== MODALS ========================================================== */
/* ====================================================================== */
#media-modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .modal-actions-container {
          .photo-pagination {
            font-size: 30px;
            line-height: 1;
          }
          .photo-pagination.photo-pagination-prev {
            padding: 15px 24px 15px 22px;
          }
          .photo-pagination.photo-pagination-next {
            padding: 15px 20px 15px 26px;
          }
        }
      }
    }
  }
}
/* ====================================================================== */